import { format } from "date-fns";

export const addBlankLocation = (
  schedule,
  setSnackBarProps,
  addScheduleLocation
) => {
  var blankLocation = {
    accessDetails: "",
    accountsContact: 0,
    address: "",
    allContracts: null,
    contractStatus: null,
    emailLog: [],
    endDate: null,
    evacuationProcedure: "",
    floorPlanLink: "",
    floorType: "",
    furnitureNotes: "",
    hazard: [],
    id: "blank",
    isArchived: null,
    isIndoor: 0,
    isOutdoor: 0,
    latitude: "",
    lightNotes: "",
    locationContracts: null,
    locationHazard: [],
    locationImage: [],
    locationPeople: [],
    locationRecce: [],
    locationRecceImage: [],
    locationType: null,
    longitude: "",
    name: null,
    parkingNotes: "",
    person: [],
    pricing: 0,
    primaryContact: 0,
    projects: [],
    projectsFk: null,
    recceStatus: "",
    roofHeight: null,
    rooms: null,
    schedule: [],
    secondaryContact: 0,
    specialNotes: "",
    startDate: null,
    sunNotes: "",
    transaction: [],
    videosLink: "",
    windowsNotes: "",
  };
  if (
    schedule.scheduleLocations.length > 0 &&
    schedule.scheduleLocations[schedule.scheduleLocations.length - 1]
      .locationFk == "blank"
  ) {
    setSnackBarProps("info", "Add one at a time", true);
  } else {
    let scheduleLocations = schedule.scheduleLocations;
    if (schedule.scheduleLocations.length < 10) {//Changed Jethro
      let blankScheduleLocation = {
        scheduleFk: schedule.id,
        locationFk: "blank",
        locationFkNavigation: blankLocation,
        contractStatus: "Send",
        currency: "NZD"
      };
      if (scheduleLocations.length > 0) {
        blankScheduleLocation.startTime =
          scheduleLocations[scheduleLocations.length - 1].endTime;
        blankScheduleLocation.endTime =
          scheduleLocations[scheduleLocations.length - 1].endTime;
      } else {
        var date = new Date(Date.parse(schedule.date));
        var eT = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          17,
          0
        );
        var sT = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          8,
          30
        );
        sT = format(sT, "yyyy-MM-dd HH:mm:ss");
        eT = format(eT, "yyyy-MM-dd HH:mm:ss");
        blankScheduleLocation.startTime = sT;
        blankScheduleLocation.endTime = eT;
      }
      addScheduleLocation(blankScheduleLocation);
    }
  }
};
