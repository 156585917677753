import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { setDialogProps } from "../../../../actions/dialog";
import {
  Card,
  TextField,
  Button,
  Grid,
  CardContent,
  Typography,
  AppBar,
  Collapse,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { setSnackBarProps } from "../../../../actions/snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { format, isValid } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import NewLocationPopUp from "../Location/NewLocationPopUp";
import FindLocationPopUp from "../Location/FindLocationPopUp";
import LocationDetailsPopUp from "../Location/LocationDetailsPopUp";
import LocationContractButton from "./LocationContractButton";
import LocationSwitch from "./LocationSwitch";
import AccessTime from "@material-ui/icons/AccessTime";
import CardContact from "../../../Project/Schedule/ContactCard";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";
import ProviderTableCell from "./ProviderTableCell";
import CachedIcon from "@material-ui/icons/Cached";
import "./scheduleLocationStyles.css";
import Snacky from "../../../Shared/Snacky";
import { checkIfLocationTimeValid } from "../../../../utils/utils";
import LocationChangeTravelReimbursmentWarning from "./LocationChangeTravelReimbursmentWarning";
import MultiCurrencyInputField from "../../../CurrencyExchange/MultiCurrencyInputField";
import { Skeleton } from "@material-ui/lab";
import { debounce } from "lodash";
import PullFromBudgetAutocomplete from "../../../Shared/BudgetItem/PullFromBudgetAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  appBar: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
    backgroundColor: "#c8c8c8",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  outlinedTypography: {
    marginRight: "5px",
    cursor: "pointer",
    border: "1px #c8c8c8",
    borderRadius: "4px",
    padding: 4,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginSides: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  noMargin: {
    margin: 0,
    padding: 0,
  },
  searchFab: {
    background: "#00b2e2",
    color: "white",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "0px",
    marginTop: "auto",
    minWidth: "40px",
    maxWidth: "40px",
    "&:hover": {
      background: "#008cb3",
    },
  },
  deleteButton: {
    color: "#000000",
    borderRadius: "20%",
    // marginRight: "-15px",
    // marginLeft: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "0px",
    marginTop: "auto",
    minWidth: "40px",
    maxWidth: "40px",
  },
  searchIcon: {
    fontSize: "16px",
  },
}));

export const ScheduleLocation = (props) => {
  const classes = useStyles();
  const [newLocationWeather, setNewLocationWeather] = useState(false);
  const [st, setSt] = useState(props.sl.startTime);
  const [et, setEt] = useState(props.sl.endTime);
  const [newLocationModalState, setNewLocationModalState] = useState(false);
  const [findLocationModalState, setFindLocationModalState] = useState(false);
  const [locationDetailsModalState, setLocationDetailsModalState] = useState({
    open: false,
    tab: 0,
  });
  const [showLocationSearch, setShowLocationSearch] = useState(false);
  const [showLocationCard, setShowLocationCard] = useState(true);
  const initialDialogProps = {
    titleText: "",
    contentText: "",
    buttonOneText: "",
    buttonTwoText: "",
    show: false,
    scheduleId: null,
  };
  const [dialogProps, setDialogProps] = useState(initialDialogProps);
  const [showNoLocationFeeWarning, setShowNoLocationFeeWarning] =
    useState(false);

  const [showTravelReimbursmentWarning, setShowTravelReimbursmentWarning] =
    useState(false);
  const [isUpdatingLocationFee, setIsUpdatingLocationFee] = useState(false);
  const [localCurrency, setLocalCurrency] = useState({
    totalAmount: props.sl.fee,
    totalAmountInNzd: props.sl.feeInNzd,
    currency: props.sl.currency,
  });

  const debouncedUpdateFee = useCallback(
    debounce((sl, payload) => {
      props.updateScheduleLocationFee(sl, payload);
    }, 500), // 500ms delay
    [] // Empty dependency array means this function is created only once
  );

  useEffect(() => {
    if (localCurrency?.totalAmount !== props.sl.fee) {
      setLocalCurrency({
        totalAmount: props.sl.fee,
        totalAmountInNzd: props.sl.feeInNzd,
        currency: props.sl.currency,
      });
    }
  }, [props.sl]);

  useEffect(() => {
    if (st !== props.sl.startTime) {
      setSt(props.sl.startTime);
    }
  }, [props.sl.startTime]);

  useEffect(() => {
    if (et !== props.sl.endTime) {
      setEt(props.sl.endTime);
    }
  }, [props.sl.endTime]);

  useEffect(() => {
    if (et != props.sl.endTime) {
      if (isValid(et)) {
        props.editScheduleLocation(
          props.schedule,
          props.sl,
          "endTime",
          format(et, "HH:mm:ss"),
          1
        );
      }
    }
  }, [et]);
  useEffect(() => {
    if (st != props.sl.startTime) {
      if (isValid(st)) {
        props.editScheduleLocation(
          props.schedule,
          props.sl,
          "startTime",
          format(st, "HH:mm:ss"),
          1
        );
      }
    }
  }, [st]);

  const dialogButtonOne = () => {
    return (
      <Button
        onClick={() => {
          props.deleteScheduleLocation(
            props.sl,
            props.schedule,
            props.location
          );
          props.promoteScheduleLocation({ sl: props.sl });
          props.setSnackBarProps("success", "Location removed", true);
          props.//b.isHidden !== 1 &&(props.project.id);
          setDialogProps(initialDialogProps);
        }}
        color="warning"
        disabled={props.project.isArchived === 1}
      >
        {dialogProps.buttonOneText}
      </Button>
    );
  };

  const dialogButtonTwo = () => {
    return (
      <Button
        onClick={() => {
          setDialogProps(initialDialogProps);
        }}
        color="primary"
        disabled={props.project.isArchived === 1}
      >
        {dialogProps.buttonTwoText}
      </Button>
    );
  };

  const dialogButtonThree = () => {
    return (
      <Button
        onClick={() => {
          props.editScheduleLocation(props.schedule, props.sl, "fee", null);
          props.setSnackBarProps("success", "Contract cancelled", true);
          props.getBudget(props.project.id);
          setDialogProps(initialDialogProps);
        }}
        color="warning"
        disabled={props.project.isArchived === 1}
      >
        {dialogProps.buttonOneText}
      </Button>
    );
  };

  const dialogButtonFour = () => {
    return (
      <Button
        onClick={() => {
          props.editScheduleLocation(props.schedule, props.sl, "fee", null);
          props.setSnackBarProps("success", "Contract cancelled", true);
          props.getBudget(props.project.id);
          setFindLocationModalState(true);
          setDialogProps(initialDialogProps);
        }}
        disabled={props.project.isArchived === 1}
        color="warning"
      >
        {dialogProps.buttonOneText}
      </Button>
    );
  };

  const dialogButtonFive = () => {
    return (
      <Button
        onClick={() => {
          setFindLocationModalState(true);
          setDialogProps(initialDialogProps);
        }}
        color="warning"
        disabled={props.project.isArchived === 1}
      >
        {dialogProps.buttonOneText}
      </Button>
    );
  };

  const dialog = () => {
    return (
      <Dialog
        open={dialogProps.show}
        onClose={() => setDialogProps(initialDialogProps)}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {dialogProps.titleText}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            {dialogProps.contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          {dialogProps.buttonOneText == "Remove"
            ? dialogButtonOne()
            : dialogProps.buttonOneText == "Switch"
            ? dialogButtonThree()
            : dialogProps.buttonOneText == "Change"
            ? dialogButtonFour()
            : dialogProps.buttonOneText == "Choose Another"
            ? dialogButtonFive()
            : ""}
          {dialogButtonTwo()}
        </DialogActions>
      </Dialog>
    );
  };

  const handleNewLocationModal = () => {
    if (newLocationModalState) {
      setNewLocationModalState(false);
      setFindLocationModalState(true);
    } else {
      setFindLocationModalState(false);
      setNewLocationModalState(true);
    }
  };

  const toggleLocationDetailsModal = () => {
    if (props.location.id === "blank") {
      findLocationModalState
        ? setFindLocationModalState(false)
        : setFindLocationModalState(true);
    } else {
      locationDetailsModalState.open
        ? setLocationDetailsModalState({ open: false, tab: 0 })
        : setLocationDetailsModalState({ open: true, tab: 0 });
    }
  };

  const setToNewLocation = (location) => {
    let rawAddress = location.address;
    let res = rawAddress.replace(/ /g, "%20");
    res = res.replace(/,/g, "%2C");
    res = res.replace(/|/, "%7C");
    setNewLocationWeather(true);
    handleNewLocationModal();
  };

  const toggleLocationCard = () => {
    if (showLocationCard && props.location) {
      setShowLocationCard(false);
    } else if (!showLocationCard && props.location) {
      setShowLocationCard(true);
    } else if (!props.location) {
      setShowLocationSearch(true);
    }
  };

  const setLoadingImagesFalse = () => {
    console.log("setLoadingImagesFalse");
  };
  const setLoadingImagesTrue = () => {
    console.log("setLoadingImagesTrue");
  };

  const returnAddressText = () => {
    if (props.location.address) {
      return props.location.address;
    } else {
      return "Select Location";
    }
  };

  const handleRemoveScheduleLocation = () => {
    if (props.schedule.scheduleLocations.length === 1) {
      props.noLocationAlert(true);
      setTimeout(() => {
        props.noLocationAlert(false);
      }, 3600);
      setDialogProps({
        titleText: "This location cannot be deleted",
        contentText: "There must be at least one location",
        buttonOneText: "Choose Another",
        buttonTwoText: "Cancel",
        show: true,
        scheduleId: null,
      });
    } else {
      if (props.sl.contractStatus === "Return") {
        props.deleteScheduleLocation(props.sl, props.schedule, props.location);
      } else {
        if (props.sl.contractStatus === "Invoiced") {
          setDialogProps({
            titleText: "This location cannot be deleted",
            contentText: "It has been invoiced",
            buttonOneText: "",
            buttonTwoText: "OK",
            show: true,
            scheduleId: null,
          });
          props.setSnackBarProps(
            "warning",
            "Can't remove, already invoiced",
            true
          );
        } else {
          setDialogProps({
            titleText: "Do you want to remove this location?",
            contentText: "This will cancel any outstanding contracts",
            buttonOneText: "Remove",
            buttonTwoText: "Cancel",
            show: true,
          });
        }
      }
    }
  };

  const handleChangeLocation = (newLocation) => {
    if (props.schedule.id == undefined) {
      props.createNewSchedule(props.schedule, newLocation, props.sl);
      setFindLocationModalState(false);
      props.setCreatingSchedule(false);
    } else {
      var hasDuplicate = false;
      for (let i = 0; i < props.schedule.scheduleLocations.length; i++) {
        if (props.schedule.scheduleLocations[i].locationFk == newLocation.id) {
          hasDuplicate = true;
          break;
        }
      }
      props.changeLocation(props.location, newLocation, props.sl);
      if (hasDuplicate) {
        props.editScheduleLocation(
          props.schedule,
          props.sl,
          "contractStatus",
          "Return",
          0
        );
      }
    }
    setFindLocationModalState(false);
  };

  const handleLocationChangeCheck = () => {
    console.log(props);
    var scheduleItems = props.contractors;
    var scheduleItemsForLocation = scheduleItems.filter(
      (si) => si.travelReimbursment !== null
    );
    var locationToChange = props.sl;
    var foundReimbursment = false;
    for (var i = 0; i < scheduleItemsForLocation.length; i++) {
      if (
        scheduleItemsForLocation[i].startTime >= locationToChange.startTime &&
        scheduleItemsForLocation[i].endTime <= locationToChange.endTime
      ) {
        foundReimbursment = true;
        break;
      }
    }

    if (foundReimbursment) {
      setShowTravelReimbursmentWarning(true);
    } else {
      props.sl.contractStatus != "Send"
        ? setDialogProps({
            titleText: "Change location?",
            contentText: "This will cancel any outstanding contracts",
            buttonOneText: "Change",
            buttonTwoText: "Cancel",
            show: true,
          })
        : setFindLocationModalState(true);
    }
  };

  // console.log("Current schedule location:", props.sl);
  console.log("Current props:", props);

  const handleChangeAllocatedRate = async (currency, fee, feeInNzd) => {
    console.log("Updating location fee:", { currency, fee, feeInNzd });
    setIsUpdatingLocationFee(true);
    setLocalCurrency({
      totalAmount: fee || 0,
      totalAmountInNzd: feeInNzd || 0,
      currency: currency || "NZD",
    });
    debouncedUpdateFee(props.sl, {
      fee: fee || 0,
      feeInNzd: feeInNzd || 0,
      currency: currency || "NZD",
    });
    setIsUpdatingLocationFee(false);
  };

  return (
    <Card
      style={{ marginTop: "5px", marginBottom: "5px" }}
      className="scheduleLocationCard"
    >
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      {showTravelReimbursmentWarning && (
        <LocationChangeTravelReimbursmentWarning
          open={showTravelReimbursmentWarning}
          handleClose={() => {
            setShowTravelReimbursmentWarning(false);
          }}
        />
      )}
      {dialogProps.show && dialog()}
      {locationDetailsModalState.open && (
        <LocationDetailsPopUp
          updateLocationContact={props.updateLocationContact}
          parent={"scheduleLocation"}
          location={props.location}
          modalState={locationDetailsModalState.open}
          initialTab={locationDetailsModalState.tab}
          handleModal={() => toggleLocationDetailsModal()}
          setLoadingImagesFalse={() => setLoadingImagesFalse()}
          setLoadingImagesTrue={() => setLoadingImagesTrue()}
        />
      )}
      {newLocationModalState && (
        <NewLocationPopUp
          modalState={newLocationModalState}
          handleModal={() => handleNewLocationModal()}
          setLocation={setToNewLocation}
          parent={"ScheduleLocation"}
        />
      )}
      {findLocationModalState && (
        <FindLocationPopUp
          modalState={findLocationModalState}
          handleModal={() => setFindLocationModalState(false)}
          handleNewLocationModal={() => handleNewLocationModal()}
          setLocation={handleChangeLocation}
        />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ height: "100%" }}>
        <AppBar position="static" className={classes.appBar}>
          {showLocationCard ? (
            <ArrowDropDownIcon
              onClick={toggleLocationCard}
              style={{ color: "black", paddingTop: "4px", cursor: "pointer" }}
            />
          ) : (
            <ArrowRightIcon
              onClick={toggleLocationCard}
              style={{ color: "black", paddingTop: "4px", cursor: "pointer" }}
            />
          )}
          <Typography
            style={{
              color: "black",
              fontSize: "10px",
              paddingTop: "6px",
              paddingBottom: "4px",
              marginLeft: "-4px",
              minWidth: "60px",
            }}
          >
            {"Location" + " " + (props.index + 1)}
          </Typography>
          <KeyboardTimePicker
            disabled={props.project.isArchived === 1}
            margin="none"
            //id={`start-time-picker`}
            className={`time-picker-schedule-location ${classes.margin}`}
            //id={`start-time-picker-${props.index + 1}`}
            label="Start Time *"
            value={st}
            error={false}
            helperText={""}
            onChange={(value) => {
              // if(checkIfLocationTimeValid(value, props.schedule)){
              if (checkIfLocationTimeValid(value, st)) {
                setSt(value);
              } else {
                setSt(value);
                props.showReschedule();
              }
            }}
            KeyboardButtonProps={{
              id: "time-picker-button",
            }}
            InputLabelProps={{
              className: "input-label-schedule-location",
            }}
            //className={classes.margin}
            keyboardIcon={<AccessTime id="time-picker-icon" />}
          />
          <KeyboardTimePicker
            disabled={props.project.isArchived === 1}
            margin="none"
            padding="none"
            className={`time-picker-schedule-location ${classes.margin}`}
            label="End Time *"
            error={false}
            helperText={""}
            value={et}
            onChange={(value) => setEt(value)}
            //className={classes.margin}
            keyboardIcon={<AccessTime id="time-picker-icon" />}
            InputLabelProps={{
              className: "input-label-schedule-location",
            }}
          />
        </AppBar>
        <Collapse in={showLocationCard}>
          <CardContent>
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={12} md={12} lg={12}>
                <div style={{ display: "flex" }}>
                  <Typography
                    style={{
                      // border: "1px solid #c8c8c8",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                      borderRadius: "5px",
                      // fontSize: "15px",
                      fontSize: "0.9em",
                      cursor: "pointer",
                      marginRight: "15px",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    onClick={toggleLocationDetailsModal}
                    component={"div"}
                    className={"pointer clickable"}
                  >
                    {returnAddressText()}
                  </Typography>
                  {props.location.id !== "blank" && (
                    <IconButton
                      disabled={
                        (props.sl && props.sl.contractStatus === "Invoiced") ||
                        props.project.isArchived === 1
                      }
                      className={props.noLocation && "animate-search"}
                      onClick={() => {
                        handleLocationChangeCheck();
                      }}
                    >
                      <CachedIcon />
                    </IconButton>
                  )}
                </div>
              </Grid>
              {props.location.id !== "blank" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 10,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ backgroundColor: "#F8F9F9" }}
                  >
                    <div
                      style={{
                        // marginTop: "10px",
                        display: "flex",
                        justifyContent: "left",
                        paddingLeft: 10,
                      }}
                    >
                      <div>
                        {props.location.id !== "blank" ? (
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <ProviderTableCell
                              location={props.location}
                              setLocationDetailsModalState={
                                setLocationDetailsModalState
                              }
                              locationDetailsModalState={
                                locationDetailsModalState
                              }
                            />
                            <FormControlLabel
                              style={
                                props.sl.locationFkNavigation
                                  .primaryContactNavigation === null ||
                                props.sl.locationFkNavigation
                                  .primaryContactNavigation.id === null
                                  ? { display: "none" }
                                  : {}
                              }
                              control={
                                <Checkbox
                                  disabled={props.project.isArchived === 1}
                                  checked={
                                    props.sl.willAttendShoot == 1 ? true : false
                                  }
                                  onChange={(e) =>
                                    props.editScheduleLocation(
                                      props.schedule,
                                      props.sl,
                                      "willAttendShoot",
                                      e.target.checked === true ? 1 : 0,
                                      1
                                    )
                                  }
                                  name="will-attend-shoot-checkedbox"
                                  color="primary"
                                />
                              }
                              label={
                                <span style={{ fontSize: "0.9em" }}>
                                  on set
                                </span>
                              }
                              labelPlacement="start"
                            />
                          </div>
                        ) : (
                          <CardContact
                            contactId={props.location.primaryContact}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: 0,
                      height: "40px",
                      // marginTop: "10px",
                    }}
                  >
                    {showNoLocationFeeWarning && (
                      <Dialog
                        open={showNoLocationFeeWarning}
                        onClose={() => setShowNoLocationFeeWarning(false)}
                        aria-labelledby="location-fee-warning-dialog-title"
                        aria-describedby="location-fee-warning-dialog-description"
                      >
                        <DialogTitle id="location-fee-warning-dialog-title">
                          {"Missing Location Fees"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="location-fee-warning-dialog-description">
                            There is no "Location Fees" budget line for this
                            project. This means that this location cannot be
                            changed to a paid location
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setShowNoLocationFeeWarning(false)}
                            color="primary"
                            autoFocus
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      </Dialog>
                    )}
                  </Grid>
                  {props.location.primaryContact && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      {isUpdatingLocationFee ? (
                        <>
                          <Skeleton height={50} />
                        </>
                      ) : (
                        <>
                          <PullFromBudgetAutocomplete
                            options={props.projectBudgetItems || []} 
                            handleOnChange={(event, newValue) => {
                              props.updateLocationBudget(
                                props.sl,
                                newValue?.budgetId || null,
                              )
                            }}
                            value={props.projectBudgetItems?.find(
                              (bi) =>
                                props.sl?.budgetItemFk ===
                                bi?.budgetId
                            )}
                          />
                          <MultiCurrencyInputField
                            totalAmount={localCurrency.totalAmount || 0}
                            nzdTotalAmount={localCurrency.totalAmountInNzd || 0}
                            size="small"
                            currency={localCurrency.currency || "NZD"}
                            handleChange={handleChangeAllocatedRate}
                            isReadOnly={props.sl?.contractStatus !== "Send"}
                            lockDropdown={props.sl?.contractStatus !== "Send"}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 10,
                            }}
                          >
                            <LocationContractButton
                              sl={props.sl}
                              l={props.location}
                              s={props.schedule}
                              editScheduleLocation={props.editScheduleLocation}
                              disabled={props.project.isArchived === 1}
                            />
                            <IconButton
                              className={classes.deleteButton}
                              disabled={props.project.isArchived === 1}
                              size="small"
                            >
                              <DeleteIcon
                                onClick={() => {
                                  handleRemoveScheduleLocation();
                                }}
                                style={{
                                  color: "rgba(0, 0, 0, 0.54)",
                                  padding: 0,
                                }}
                              />
                            </IconButton>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </MuiPickersUtilsProvider>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dialog: state.dialog,
  project: state.schedules.project,
  schedule: state.schedules.schedule,
  budgetLoading: state.budget.budgetLoading,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(ScheduleLocation);
