import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { setSnackBarProps } from "../../../../actions/snackbar";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControl,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import "../Location/locationStyles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import "./AddAgentPopUp.css";
import { checkEmail } from "../../../../utils/Validation";
import Snacky from "../../../Shared/Snacky";
import CurrencyDropdownMenu from "../../../CurrencyExchange/CurrencyDropdownMenu";
import { getCurrencies } from "../../../../utils/GeneralUtils";
import ProcessingSpinner from "../../../Shared/Spinner/ProcessingSpinner";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    width: "130%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 390,
    width: "25vW",
    overflow: "hidden",
  },
  paperMatchContractorPopUp: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 550,
    width: "25vW",
    overflow: "hidden",
  },
}));

const optionStyles = makeStyles((theme) => ({
  option: {
    // '&[aria-disabled="true"]': {
    //     color: "white",
    //     backgroundColor: "#00b2e2"
    // },
    blueBackground: {
      color: "white",
      backgroundColor: "#00b2e2",
    },
  },
}));

const StyledButton = withStyles(() => ({
  root: {
    marginLeft: "auto",
    background: "#00b2e2",
    color: "white",
    "&:hover": {
      background: "#008cb3",
    },
  },
}))(Button);

const filter = createFilterOptions();

function AddAgentPopUp(props) {

  const classes = useStyles();
  const optionClasses = optionStyles();
  const [companyList, setCompanyList] = useState([]);
  const [getCompany, setGetCompany] = useState(true);
  const [company, setCompany] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [requiredInfo, setRequiredInfo] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    companyFk: null,
    companyFkNavigation: null,
  });
  const [showProcessingSpinner, setShowProcessingSpinner] = useState(false);

  const handleRequiredInfoChange = (field) => (event) => {
    var value = event.target.value;
    if (field === "emailAddress") {
      setRequiredInfo({ ...requiredInfo, [field]: value.trim() });
    } else {
      setRequiredInfo({ ...requiredInfo, [field]: value });
    }
  };

  const checkRequiredFields = () => {
    var requiredFields = requiredInfo;
    if (
      requiredFields.firstName !== "" &&
      requiredFields.firstName !== null &&
      requiredFields.lastName !== "" &&
      requiredFields.lastName !== null &&
      requiredFields.phoneNumber !== "" &&
      requiredFields.phoneNumber !== null &&
      requiredFields.emailAddress !== "" &&
      requiredFields.emailAddress !== null &&
      !checkEmail(requiredFields.emailAddress)
    ) {
      return true;
    }
    return false;
  };

  const saveAgent = () => {
    props.handleSave(requiredInfo);
    props.handleModal();
  };

  const changeCompany = (object, value) => {
    if (typeof object === "string") {
    } else if (typeof object !== "string" && value === undefined) {
      //Company Selected From Dropdown
      setCompany(object);
      try {
        setRequiredInfo({ ...requiredInfo, ["companyFk"]: object.id });
      } catch (e) {
        //Handle error
      }
    } else if (value !== null) {
    }
  };

  const createNewCompany = async (company) => {
    setShowProcessingSpinner(true);
    var res = await Axios.post(`Companies/PostCompany`, company);
    setShowProcessingSpinner(false);
    if (res?.data) {

      setCompanyList([...companyList, res.data]);
      setRequiredInfo({ ...requiredInfo, ["companyFk"]: res.data.id });
      props.setSnackBarProps("success", "Created company", true);
      return res?.data;
    } else {
      props.setSnackBarProps("warning", "Failed to create company", true);
      return null;
    }
  };

  useEffect(() => {
    if (getCompany) {
      Axios.get(`Companies/GetCompanies`, {
        params: { organisationId: props.organisationId, isAgency: 1 },
      })
        .then((res) => {
          setCompanyList(res.data);
          setGetCompany(false);
        })
        .catch((e) => {
          setGetCompany(false);
        });
    }
  }, []);

  useEffect(() => {
    if (company === null) {
      setRequiredInfo({
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        companyFk: null,
        companyFkNavigation: null,
      });
    }
  }, [company]);

  const handleChangeCompanyCurrency = (currency) => {
    if (company) {
      Axios.put(
        `Companies/UpdateAgencyCurrency/${company.id}/${
          currency || "NZD"
        }`
      )
        .then(({ data }) => {
          setCompanyList(companyList.map((c) => (c.id === data.id ? {...c, currency: currency || "NZD"} : c)));
          setCompany({...company, currency: currency || "NZD" });
        })
        .catch((e) => {
          alert("Unable to Update Currency");
        });
    } else {
      alert("Company is not selected");
    }
  };
  return (
    <div>
      {/* <Modal
        open={props.modalState}
        onClose={(e, reason) => reason !== "backdropClick" ? props.handleModal : () => {}}
        className={
          props.parent === "AddTalentContact" ||
            props.parent === "AddInfuencerContact"
            ? "add-agentpop-up-new-schedule-item"
            : props.parent === "ContractorPopUp"
              ? "add-agent-pop-up-edit-talent-contact"
              : classes.modal
        }
      > */}
      <Dialog
        open={props.modalState}
        onClose={(e, reason) => reason !== "backdropClick" ? props.handleModal : () => { }}
        maxWidth="md"
      >
        <div>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                Add New Agent
              </Typography>
              <IconButton
                aria-label="Close Agent Popup"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={props.handleModal}
                disabled={showProcessingSpinner}
                color="inherit"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent
            className={
              props.parent === "ContractorPopUp"
                ? classes.paperMatchContractorPopUp
                : classes.paper
            }
          >
            <Grid container spacing={2}>
              <div className="add-agent-popup-currency-section">
                <CurrencyDropdownMenu
                  isReadOnly={!(company !== null)}
                  originalCurrency={company?.currency ? company?.currency : props.currency || "NZD"}
                  handleChangeCurrency={(currencyObj) =>
                    handleChangeCompanyCurrency(currencyObj.value)
                  }
                  selectedCurrencies={getCurrencies()}
                />
                <Autocomplete
                  //classes={optionClasses}
                  inputValue={inputValue || ""}
                  onInputChange={(event, newInputValue) => {
                    if (company !== null && company.name !== newInputValue) {
                      setCompany(null);
                    }
                    setInputValue(newInputValue);
                  }}
                  value={companyList.find((c) => c.id === company?.id) || ""}
                  onChange={async (event, newValue) => {
                    // Create a new value from the user input
                    if (newValue && newValue.inputValue) {
                      var company = {
                        name: newValue.inputValue,
                        isAgency: 1,
                        organisationFk: props.organisationId,
                        gstRegistered: 1,
                        currency: props.currency || "NZD"
                      };
                      var createdCompany = await createNewCompany(company);
                      changeCompany(createdCompany);
                      return;
                    } else {
                      changeCompany(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    console.log("filterOptions", options, params);
                    if (params.inputValue === "") {
                      return options;
                    }
                    const filtered = filter(options, params);
                    const createOption = [
                      {
                        inputValue: params.inputValue,
                        name: `Create "${params.inputValue}"`,
                      },
                    ];
                    return createOption.concat(filtered);
                  }}
                  selectOnFocus={false}
                  clearOnBlur={false}
                  disableClearable={true}
                  options={companyList}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option?.inputValue) {
                      return option.name;
                    }
                    // Regular option
                    return option?.name;
                  }}
                  renderOption={(option, { selected }) => {
                    const checkOption = 'Create "';
                    if (option?.name?.includes(checkOption)) {
                      return (
                        <React.Fragment>
                          <span className={"blueText"}>{option.name}</span>
                        </React.Fragment>
                      );
                    } else {
                      return option.name;
                    }
                  }}
                  style={{ width: "auto" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Company" variant="outlined" />
                  )}
                  disabled={showProcessingSpinner}
                />
              </div>
              {showProcessingSpinner && (
                <Grid item xs={12} md={12} lg={12}>
                  <ProcessingSpinner
                    duration={60}
                    header="Creating company"
                    body="Please wait while we create the company"
                  />
                </Grid>
              )}
              {company !== null && (
                <React.Fragment>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        required
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        id="fname-input"
                        label="Agent First Name"
                        value={requiredInfo.firstName}
                        onChange={handleRequiredInfoChange("firstName")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        required
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        id="lname-input"
                        label="Agent Last Name"
                        value={requiredInfo.lastName}
                        onChange={handleRequiredInfoChange("lastName")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        required
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        id="phone-input"
                        label="Agent Phone"
                        value={requiredInfo.phoneNumber}
                        onChange={handleRequiredInfoChange("phoneNumber")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        required
                        multiline={true}
                        variant="outlined"
                        style={{ width: "100%" }}
                        id="email-input"
                        label="Agent Email"
                        value={requiredInfo.emailAddress}
                        error={checkEmail(requiredInfo.emailAddress)}
                        onChange={handleRequiredInfoChange("emailAddress")}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            <div
              style={
                checkRequiredFields()
                  ? { display: "flex", justifyContent: "flex-end" }
                  : { display: "none" }
              }
            >
              <FormControl style={{ marginTop: "10px" }}>
                <StyledButton onClick={saveAgent}>Submit</StyledButton>
              </FormControl>
            </div>
          </DialogContent>
          {props.snackbar.open && (
            <Snacky
              snackprops={props.snackbar}
              setSnackBarProps={props.setSnackBarProps}
            />
          )}
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
})(AddAgentPopUp);
