import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  editScheduleItem,
  cancelInvoice,
} from "../../../../actions/scheduleItems";
import axios from "axios";
import {
  ButtonGroup,
  Grid,
  Grow,
  Button,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { getCurrentTime } from "../../../../utils/TimeHelpers";
import { contains } from "../../../../utils/utils";
import { setSnackBarProps } from "../../../../actions/snackbar";
import Axios from "axios";
import "../Schedule.css";
import { useUserContext } from "../../../../context/user/UserContext";
import { useAuth } from "../../../../context/auth/AuthContext";

const changeButton = (contractStatus, category) => {
  switch (contractStatus) {
    case "Disabled":
      return { color: "#c8c8c8", options: ["Send"] };
    case "Send":
      return { color: "#3f51BF", options: ["Send"] };
    case "Pending":
      if (category === "ContractorTalent") {
        return {
          color: "#FFA500",
          options: ["Pending", "Cancel Contract"],
        };
      } else {
        return {
          color: "#FFA500",
          options: ["Pending", "Cancel Contract", "Resend"],
        };
      }
    case "Signed":
      if (category === "ContractorTalent") {
        return {
          color: "#F987C5",
          options: ["Signed", "Cancel Contract"],
        };
      } else {
        return {
          color: "#F987C5",
          options: ["Signed", "Cancel Contract", "Send for Invoicing"],
        };
      }
    case "Declined":
      return {
        color: "#FF0000",
        options: ["Declined", "Cancel Contract"],
      };
    case "Invoiced":
      return { color: "#008000", options: ["Invoiced"] };
    case "AwaitingPayment":
      return { color: "#008000", options: ["PO Sent"] };
    case "Sent":
      return { color: "#008000", options: ["Sent", "Cancel Calendar"] };
    case "InvoicePending":
      return {
        color: "#FFA500",
        options: ["Invoice Pending", "Cancel Invoice"],
      };
      default:
        return {
          color: "#c8c8c8",
          options: ["Unknown"],
        };
  }
};

const ContractButton = (props) => {
    const { user } = useAuth();
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [buttonProps, setButtonProps] = useState(
    changeButton(props.si.contractStatus, props.rescheduleButton)
  );
  const anchorRef = useRef(null);
  let si = props.si;
  useEffect(() => {
    setButtonProps(changeButton(si.contractStatus, props.rescheduleButton));
  }, [si.contractStatus]);

  const handleClick = () => {
    if (si.contractStatus === "Disabled") {
      console.log("This button is disabled");
    } else {
      console.log(si);
      if (buttonProps.options[selectedIndex] === "Send" && si.allocatedRates == 0 && si.equipmentHire == null && si.category != "Unpaid" &&
          (props.providerType === "Talent" || props.providerType === "Contractor")) {
        props.editScheduleItem(
          si,
          props.providerType.toLowerCase(),
          "category",
          "Unpaid",
          1, user?.email
        );
        handleChangedToUnpaid();
      }
      else if (buttonProps.options[selectedIndex] === "Send" && props.rescheduleButton !== "ContractorTalent") {
        if (props.providerType === "Talent") {
          if (
            si.talentContractStatus === "Complete" ||
            si.talentContractType == "BuyOut"
          ) {
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Pending",
              1, user?.email
            );
            handleSendContract();
          } else if (si.category.trim() == "Unpaid") {
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Sent",
              1, user?.email
            );
            handleSendContract();
          } else {
            console.log(si);
          }
        } else {
          if (si.category.trim() == "Unpaid") {
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Sent",
              1, user?.email
            );
            handleSendContract();
          } else {
            props.editScheduleItem(
              si,
              props.providerType.toLowerCase(),
              "contractStatus",
              "Pending",
              1, user?.email
            );
            handleSendContract();
          }
        }
      }
    }
  };
  const handleMenuItemClick = (event, index) => {
    console.log(si);
    console.log(buttonProps.options[index]);
    if (props.parentIsClosingProject) {
      console.log(buttonProps.options[index]);
      // -------------------- handle closing project stuff ---------------------//
      if (buttonProps.options[index] === "Cancel Contract") {
        axios
          .put(
            `ScheduleItems/UpdateContractStatusProjectClose/${si.scheduleItemId}`,
            {
              scheduleItemId: si.scheduleItemId,
              contractStatus: "Send",
            }
          )
          .then((res) => {
            props.spliceProjectActiveItem(si.id);
            if (si.contractStatus === "Pending") {
              //handleCancelContract();
            } else if (si.contractStatus === "Signed") {
              //handleCancelSignedContract();
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => { });
      } else if (buttonProps.options[index] === "Send for Invoicing") {
        props.spliceProjectActiveItem(si.id);
        sendCloseProjectInvoice();
      } else if (buttonProps.options[index] === "Resend") {
        var url = "";
        if (props.providerType === "Talent") {
          url = `ResendMail/ResendTalentContract/${si.id}`;
        } else {
          url = `ResendMail/ResendContractorContract/${si.id}`;
        }
        axios
          .post(url, {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      }
      setOpen(false);
      // --------------------------- end closing project item functions ---------------------//
    } else {
      if (buttonProps.options[index] === "Cancel Contract") {
        axios
          .put(`ScheduleItems/UpdateContractStatus/${si.id}`, {
            id: si.id,
            contractStatus: "Send",
          })
          .then((res) => {
            if (si.contractStatus === "Pending") {
              props.editScheduleItem(
                si,
                props.providerType.toLowerCase(),
                "contractStatus",
                "Send",
                1,  user?.email
              );
              handleCancelContract();
            } else if (si.contractStatus === "Signed") {
              props.editScheduleItem(
                si,
                props.providerType.toLowerCase(),
                "contractStatus",
                "Send",
                1, user?.email
              );
              handleCancelSignedContract();
            } else if (si.contractStatus === "Declined") {
              props.editScheduleItem(
                si,
                props.providerType.toLowerCase(),
                "contractStatus",
                "Send",
                1, user?.email
              );
              handleCancelDeclinedContract();
            }
            if (props.providerType === "Talent") {
              props.editScheduleItem(
                si,
                props.providerType.toLowerCase(),
                "contractStatus",
                "Send",
                1, user?.email
              );
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => { });
      } else if (buttonProps.options[index] === "Send for Invoicing") {
        props.editScheduleItem(
          si,
          props.providerType.toLowerCase(),
          "contractStatus",
          "InvoicePending",
          1, user?.email
        );
        sendInvoice();
      } else if (buttonProps.options[index] === "Resend") {
        var url = "";
        if (props.providerType === "Talent") {
          url = `ResendMail/ResendTalentContract/${si.id}`;
        } else {
          url = `ResendMail/ResendContractorContract/${si.id}`;
        }
        axios
          .post(url, {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      } else if (buttonProps.options[index] === "Cancel Invoice") {
        props.cancelInvoice(
          si,
          props.providerType.toLowerCase(),
          "contractStatus",
          "Signed"
        );
      } else if (buttonProps.options[index] === "Cancel Calendar") {
        props.editScheduleItem(
          si,
          props.providerType.toLowerCase(),
          "contractStatus",
          "Send",
          1, user?.email
        );
        handleCancelCalendar();
      }
      setOpen(false);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    if (anchorRef.current && contains(anchorRef.current, event.target)) {
      return;
    }
    setOpen(false);
  };

  const sendInvoice = () => {
    axios
      .post(`Mail/SendInvoice/${si.personFk}/${si.id}`, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      })
      .then((res) => { })
      .catch((e) => {
        console.log(e);
      });
  };

  const sendCloseProjectInvoice = () => {
    axios
      .post(`Mail/SendInvoice/${si.personFk}/${si.scheduleItemId}`, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      })
      .then((res) => { })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancelContract = () => {
    var url = "";
    if (props.providerType === "Talent") {
      url = `TalentContracts/UpdateOnCancelContract/${si.id}`;
    } else {
      url = `ContractorContracts/UpdateOnCancelContract/${si.id}`;
    }
    axios
      .put(url, {
        dateCancelled: getCurrentTime(),
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancelSignedContract = () => {
    var url;
    if (props.providerType === "Talent") {
      url = `TalentContracts/UpdateOnCancelSignedContract/${si.id}`;
    } else {
      url = `ContractorContracts/UpdateOnCancelSignedContract/${si.id}`;
    }
    axios
      .put(url, {
        dateCancelled: getCurrentTime(),
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSendContract = () => {
    if (
      si.category.toLowerCase().trim() === "unpaid" ||
      props.providerType === "Client" ||
      props.providerType === "Staff"
    ) {
      //SendScheduleItemEvent
      axios
        .post(`GoogleCalendar/SendScheduleItemEvent/${si.id}`)
        .then((res) => {
          console.log("Sent Calendar");
          axios
            .put(`ScheduleItems/UpdateContractStatus/${si.id}`, {
              id: si.id,
              contractStatus: "Sent",
            })
            .then((res) => {
              console.log(res.data);
              props.providerType === "Client" || props.providerType === "Staff"
                ? props.editScheduleItem(
                  si,
                  props.providerType.toLowerCase(),
                  "contractStatus",
                  "Sent",
                  1, user?.email
                )
                : console.log("invite sent");
            });
        })
        .catch((e) => {
          console.log("Error sending Calendar");
        });
    } else {
      var url;
      var url2;
      if (props.providerType === "Contractor") {
        url = `Mail/SendScheduleContractEmail/${si.personFk}`;
        url2 = `ContractorContracts/PostContractorContract`;
      } else if (props.providerType === "Talent") {
        url = `Mail/SendTalentContractEmail/${si.personFk}`;
        if (si.talentContractType === "BuyOut") {
          url2 = `TalentContracts/PostTalentContract/${si.id}`;
        } else if (si.talentContractType === "Restricted") {
          url2 = `TalentContracts/UpdateRestrictedContractOnSend/${si.id}`;
        }
      }
      axios
        .post(url2, {
          scheduleItemFk: si.id,
          dateSent: getCurrentTime(),
          status: "Sent",
        })
        .then((res) => {
          console.log(res.data);
          axios
            .post(url, {
              scheduleItem: {
                id: si.id,
                category: si.category,
                providerName: si.providerName,
                allocatedRates: si.allocatedRates,
                startTime: si.startTime,
                endTime: si.endTime,
              },
              sender: {
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
              },
            })
            .then((res) => {
              console.log(res);
              axios.put(`ScheduleItems/UpdateContractStatus/${si.id}`, {
                id: si.id,
                contractStatus: "Pending",
              });
              props.setSnackBarProps({
                open: true,
                severity: "success",
                text: "Contract Sent",
              });
            })
            .catch((e) => {
              console.log(e);
              props.editScheduleItem(
                si,
                props.providerType.toLowerCase(),
                "contractStatus",
                "Send",
                1, user?.email
              );
              props.setSnackBarProps({
                open: true,
                severity: "warning",
                text: "Failed to Send Contract. Please try again",
              });
            });
        })
        .catch((err) => {
          console.log(err);
          props.editScheduleItem(
            si,
            props.providerType.toLowerCase(),
            "contractStatus",
            "Send",
            1, user?.email
          );
          props.setSnackBarProps(
            "warning",
            "Failed to Send Contract. Please try again",
            true
          );
        });
    }
  };

  const handleCancelDeclinedContract = () => {
    Axios.patch(`ScheduleItems/CancelDeclinedContract/${si.id}`, si)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangedToUnpaid = () => {
    props.editScheduleItem(
      si,
      props.providerType.toLowerCase(),
      "contractStatus",
      "Sent",
      1, user?.email
    );
    axios
      .post(`GoogleCalendar/SendScheduleItemEvent/${si.id}`)
      .then((res) => {
        console.log("Sent Calendar");
        axios
          .patch(`ScheduleItems/HandleChangeToUnpaid/${si.id}`, {
            id: si.id,
            contractStatus: "Sent",
          })
          .then((res) => {
            console.log(res.data);
            props.providerType === "Client" || props.providerType === "Staff"
              ? props.editScheduleItem(
                si,
                props.providerType.toLowerCase(),
                "contractStatus",
                "Sent",
                1, user?.email
              )
              : console.log("invite sent");
          });
      })
      .catch((e) => {
        console.log("Error sending Calendar");
      });
  }

  const handleCancelCalendar = () => {
    axios.patch(`GoogleCalendar/DeleteScheduleItemEvent/${si.id}`, {
      id: si.id,
    }).then((res) => {
      console.log(res);
    }).catch((e) => {
      console.log(e);
    });
  }

  return (
    <Grow in={checked} mountOnEnter unmountOnExit>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            disabled={props.disabled !== undefined ? props.disabled : false}
            variant="contained"
            // color="secondary"
            ref={anchorRef}
            aria-label="split button"
            size="small"
            style={{
              // width: "100px",
              backgroundColor: buttonProps.color,
            }}
            className="contract-button-group"
          >
            <Button
              onClick={handleClick}
              style={{
                padding: "0px 0px 0px 0px",
                width: "80%",
                backgroundColor: buttonProps.color,
                color: "white",
              }}
            >
              <span className="contract-button-text">{buttonProps.options[selectedIndex]}</span>
            </Button>
            <Button
              //color="secondary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select declined option"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{
                width: "20%",
                backgroundColor: buttonProps.color,
                color: "white",
              }}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: "999",
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {buttonProps.options.map(
                        (option, index) =>
                          index > 0 && (
                            <MenuItem
                              key={option}
                              disabled={index === 0}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              } //console.log(event)}
                            >
                              {option}
                            </MenuItem>
                          )
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grow>
  );
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  editScheduleItem,
  setSnackBarProps,
  cancelInvoice,
})(ContractButton);
