import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import Loader from "../../../Loader";
import CateringPopUp from "../../Catering/CateringPopUp";
import { ScheduleLocation } from "../ScheduleLocation/ScheduleLocation";
import { NewScheduleTitle, EditScheduleTitle } from "./EditNewTitle";
import { makeStyles } from "@material-ui/core/styles";
import { addBlankLocation } from "./editScheduleUtils";
import CloseIcon from "@material-ui/icons/Close";
import "./Reschedule.scss";
import {
  LocationOn as LocationOnIcon
} from "@material-ui/icons";
import {
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import "date-fns";
import { format, isValid } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  Day,
} from "@material-ui/pickers";
import {
  changeLocation,
  createNewSchedule,
  deleteScheduleLocation,
  editScheduleLocation,
  promoteScheduleLocation,
  noLocationAlert,
  updateScheduleLocationFee,
  updateLocationBudget
} from "../../../../actions/schedules";
import Snacky from "../../../Shared/Snacky";
import {
  getValueOrEmptyString,
  sortArrayByStartTime,
  checkIfScheduleItemsValid
} from "../../../../utils/utils";
import { editScheduleItem } from "../../../../actions/scheduleItems";
import ContractorRow from "./RescheduleContractorRow";
import LocationRow from "./RescheduleLocationRow";
import CateringReorderPopUp from "./CateringReorderPopUp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "scrollable",
  },
  alignRight: {
    marginLeft: "auto",
    marginRight: 0,
  },
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  noMargin: {
    margin: 0,
    padding: 0,
  },
  addLocationButton: {
    marginTop: "5px",
    color: "white",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto",
    maxHeight: "80vH"
  },
}));

const EditSchedule = (props) => {
  let {
    allScheduleLocations,
    changeLocation,
    createNewSchedule,
    deleteScheduleLocation,
    promoteScheduleLocation,
    editScheduleLocation,
    noLocationAlert,
    noLocation,
    scheduleItems,
    allScheduleItems,
    schedule,
    contractors,
    updateScheduleLocationFee,
    updateLocationBudget,
  } = props;
  const classes = useStyles();
  const [addButtonText, setAddButtonText] = useState("Add Location");
  const [cateringModalState, setCateringModalState] = useState(false);
  const [cateringOrder, setCateringOrder] = useState(null);
  const [loadedScheduleInfo, setLoadedScheduleInfo] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(props.schedule.date);
  const [inviteesModal, setInviteesModal] = useState(false);
  const [newDate, setNewDate] = useState();
  const [timeErrorText, setTimeErrorText] = useState("");
  const [showTimeError, setShowTimeError] = useState(false);

  const [shakeTable, setShakeTable] = useState(false);
  const [cateringLoading, setCateringLoading] = useState(true);
  const [showCateringReorder, setShowCateringReorder] = useState(false);
  const [invalidSl, setInvalidSl] = useState(false);

  const [showScheduleError, setShowScheduleError] = useState(false);

  useEffect(() => { }, [noLocationAlert]);

  const checkForDate = (date) => {
    var found = false;
    for (var i = 0; i < props.scheduleDates.length; i++) {
      if (props.scheduleDates[i].substring(0, 10) == date.substring(0, 10)) {
        found = true;
        break;
      }
    }
    return found;
  };


  useEffect(() => {
    setCateringLoading(true)
    if (Object.keys(props.schedule).length !== 0) {
      if (props.schedule.cateringOrdered === 1 || props.schedule.cateringOrdered === 2) {
        Axios.get(`Catering/GetCatering/${props.schedule.id}`)
          .then((res) => {
            setCateringOrder(res.data);
            setCateringLoading(false)
          })
          .catch((e) => {
            setCateringOrder(null);
            setCateringLoading(false)
          });
      } else {
        setCateringOrder(null);
        setCateringLoading(false);
      }
      if (props.schedule.scheduleLocations.length == 10) {//Changed - Jethro
        setAddButtonText("Ten Max");//"Five Max"
      } else {
        setAddButtonText("Add Location");
      }
    }
  }, [props.schedule]);

  useEffect(() => {
    console.log("CateringChanged")
    console.log(cateringOrder)
  }, [cateringOrder]);

  useEffect(() => {
    if (scheduleDate != props.schedule.date) {
      let todayMidnight = new Date();
      todayMidnight = todayMidnight.setHours(0, 0, 0, 0);
      if (isValid(scheduleDate) && new Date(scheduleDate) >= todayMidnight) {
        props.editSchedule(
          "date",
          format(scheduleDate, "yyyy-MM-dd"),
          props.schedule,
          1
        );
        props.updateScheduleItemDates(format(scheduleDate, "yyyy-MM-dd"));
      } else if (isValid(scheduleDate) && new Date(scheduleDate) < new Date()) {
        props.setSnackBarProps("warning", "That's in the past", true);
      }
    }
  }, [scheduleDate]);

  useEffect(() => {
    if (shakeTable) {
      setTimeout(() => {
        console.log("Timeout")
        setShakeTable(false);
      }, 1000)//Match timeout with the reschedule-row-shake animation in Reschedule.scss
    }
  }, [shakeTable])

  useEffect(() => {
    console.log("ScheduleLocations Changed")
    if (!checkIfScheduleItemsValid(schedule)) {
      setShakeTable(true)
    }
  }, [allScheduleLocations])

  const checkForCurrentSignedContracts = (e) => {
    if (getValueOrEmptyString(scheduleItems.contractor) !== "" &&
      getValueOrEmptyString(scheduleItems.talent) !== "" &&
      getValueOrEmptyString(allScheduleLocations) !== "") {
      let count = 0;
      scheduleItems.contractor.forEach((item) => {
        if (item.contractStatus === "Signed") {
          // setRescheduleModal(true);
          setInviteesModal(true);
          console.log("Contractor Found");
          setNewDate(e);
          count++;
          return;
        }
      });
      scheduleItems.talent.forEach((item) => {
        if (item.contractStatus === "Signed") {
          // setRescheduleModal(true);
          setInviteesModal(true);
          console.log("Talent Found");
          setNewDate(e);
          count++;
          return;
        }
      });
      allScheduleLocations.forEach((item) => {
        if (item.contractStatus === "Signed") {
          // setRescheduleModal(true);
          setInviteesModal(true);
          console.log("Location Found");
          setNewDate(e);
          count++;
          return;
        }
      });
      if (count === 0 && props.schedule.cateringOrdered === 0) {
        setScheduleDate(e);
      }
      else if (count === 0 && props.schedule.cateringOrdered === 1) {
        setShowCateringReorder(true);
        // props.cancelCatering(props.schedule, cateringOrder)
        // setScheduleDate(e);
      }
    } else {
      setScheduleDate(e);
    }

  };

  const updateInviteesModalComplete = () => {
    if (props.schedule.cateringOrdered === 1) {
      setShowCateringReorder(true);
      // props.cancelCatering(props.schedule, cateringOrder)
      // setScheduleDate(newDate);
      // setInviteesModal(false);
    }
    else {
      setInviteesModal(false);
      setScheduleDate(newDate);
    }
  };

  const getSentRows = (type, scheduleItems) => {
    return (
      getValueOrEmptyString(scheduleItems) !== "" && scheduleItems.filter(
        (item) =>
          item.contractStatus === "Signed" ||
          item.contractStatus === "Pending" ||
          item.contractStatus === "Sent"
      ).length > 0 &&
      scheduleItems.filter(//sortArrayByStartTime(
        (item) =>
          item.contractStatus === "Signed" ||
          item.contractStatus === "Pending" ||
          item.contractStatus === "Sent"
      )
        .map((row) => getContractorRow(type, row))
    )
  }

  const getUnsentRows = (type, scheduleItems) => {
    return (
      getValueOrEmptyString(scheduleItems) !== "" && scheduleItems.filter(
        (item) => item.contractStatus === "Send"
      ).length > 0 &&
      scheduleItems
        .filter((item) => item.contractStatus === "Send")
        .map((row) => getContractorRow(type, row))
    )
  }

  const getContractorRow = (category, row) => {
    return (
      <ContractorRow
        category={category}
        row={row}
        setTimeErrorText={setTimeErrorText}
        setShowTimeError={setShowTimeError}
        editScheduleItem={editScheduleItem}
      />
    )
  }
  const getLocationRow = (category, row) => {
    return (
      <LocationRow
        category={category}
        row={row}
        setTimeErrorText={setTimeErrorText}
        setShowTimeError={setShowTimeError}
        editScheduleItem={editScheduleItem}
      />
    )
  }

  var table = (
    <div className={classes.root}>
      {showCateringReorder && (
        <CateringReorderPopUp
          modalState={showCateringReorder}
          handleClose={() => setShowCateringReorder(false)}
          cancelOrder={() => {
            props.cancelCatering(props.schedule, cateringOrder)
            setScheduleDate(newDate);
            setInviteesModal(false);
            setShowCateringReorder(false);
          }}
          rescheduleOrder={() => {
            props.rescheduleCatering(props.schedule, cateringOrder, newDate);
            setInviteesModal(false);
            setShowCateringReorder(false);
            setScheduleDate(newDate);
          }}
        />
      )}
      {/* Invitees Modal */}
      <Modal
        className={classes.modal}
        open={inviteesModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <div>
          <AppBar position="static" style={{ background: "#217F8B" }}>
            <Toolbar variant="dense" style={{ paddingRight: 0 }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ flexGrow: 1 }}
              >
                {invalidSl ? `Location time error. Please Fix before continuing` : `Rescheduling - Check/Update below list of current contracts`}
              </Typography>
              <IconButton
                style={invalidSl ? {
                  display: "none"
                } : {}}
                onClick={() => {
                  setInvalidSl(false)
                  setInviteesModal(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {showTimeError && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={showTimeError}
              autoHideDuration={8000}
              onClose={() => setShowTimeError(false)}
            >
              <MuiAlert severity="warning">{timeErrorText}</MuiAlert>
            </Snackbar>
          )}
          <div className={classes.paper}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="reschedule-new-date">
                <KeyboardDatePicker
                  className="reschedule-new-date-picker"
                  format="dd/MM/yyyy"
                  id="schedule-date-picker"
                  label="Schedule Date *"
                  value={newDate}
                  error={false}
                  helperText={""}
                  minDate={new Date()}
                  onChange={(e) => {
                    setNewDate(e);
                  }}
                />
              </div>
              {/* ScheduleLocations Table */}
              <Table
                className={`${classes.table} reschedule-warning-table`}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow className="reschedule-table-head">
                    <TableCell style={{ background: "white" }} />
                    <TableCell className="reschedule-table-head-cell reschedule-location-header">
                      Location
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell reschedule-fee-header">
                      Fee
                    </TableCell>
                    <TableCell
                      className="reschedule-table-head-cell reschedule-start-time-header"
                    >
                      Start Time
                    </TableCell>
                    <TableCell
                      className="reschedule-table-head-cell reschedule-end-time-header"
                    >
                      End Time
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow >
                    <TableCell rowSpan="0" style={{ width: 30 }}>
                      <LocationOnIcon />
                    </TableCell>
                  </TableRow>
                  {allScheduleLocations.length > 0 &&
                    sortArrayByStartTime(allScheduleLocations.map((row) => getLocationRow("Location", row)))}
                </TableBody>
              </Table>
              <hr />
              {/* Contracts Table */}
              <Table
                className={`${classes.table} reschedule-warning-table ${shakeTable && `reschedule-row-shake`}`}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow className="reschedule-table-head">
                    <TableCell style={{ background: "white" }} />
                    <TableCell className="reschedule-table-head-cell reschedule-who-header">
                      Who
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell reschedule-category-header">
                      Category
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell reschedule-role-header">
                      Role
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell reschedule-fee-header">
                      Fee
                    </TableCell>
                    <TableCell
                      className="reschedule-table-head-cell reschedule-start-time-header"
                    >
                      Start Time
                    </TableCell>
                    <TableCell
                      className="reschedule-table-head-cell reschedule-end-time-header"
                    >
                      End Time
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell">
                      Status
                    </TableCell>
                    <TableCell className="reschedule-table-head-cell">
                      1st Location
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="vertical-sent">
                    <TableCell rowSpan="0" className="vertical-text">
                      Sent
                    </TableCell>
                  </TableRow>
                  {getSentRows("Contractor", scheduleItems.contractor)}
                  {getSentRows("Talent", scheduleItems.talent)}
                  {getSentRows("Staff", scheduleItems.staff)}
                  {getSentRows("Client", scheduleItems.client)}
                </TableBody>
              </Table>
              <hr />
              {/* UnsentTable */}
              <Table
                className={`${classes.table} reschedule-warning-table ${shakeTable && `reschedule-row-shake`}`}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow className="vertical-not-sent">
                    <TableCell rowSpan="0" className="vertical-text">
                      Not Sent
                    </TableCell>
                  </TableRow>
                  {getUnsentRows("Contractor", scheduleItems.contractor)}
                  {getUnsentRows("Talent", scheduleItems.talent)}
                  {getUnsentRows("Staff", scheduleItems.staff)}
                  {getUnsentRows("Client", scheduleItems.client)}
                </TableBody>
              </Table>
            </MuiPickersUtilsProvider>
            <div className="reschedule-warning-buttons">
              <Button
                variant="contained"
                style={invalidSl ? {
                  display: "none"
                } : {}}
                onClick={() => {
                  setInvalidSl(false)
                  setInviteesModal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!checkIfScheduleItemsValid(schedule)}
                className="reschedule-button"
                onClick={updateInviteesModalComplete}
              >
                {invalidSl ? `Continue` : `Update Date`}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Of Invitees Modal */}
      <Snacky
        snackprops={props.snackbar}
        setSnackBarProps={props.setSnackBarProps}
      />
      {showScheduleError && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showScheduleError}
          autoHideDuration={8000}
          onClose={() => setShowScheduleError(false)}
        >
          <MuiAlert severity="warning">Schedule name is empty</MuiAlert>
        </Snackbar>
      )}
      {cateringModalState && props.schedule.scheduleLocations && (
        <CateringPopUp
          cateringOrder={cateringOrder}
          cateringOrdered={props.schedule.cateringOrdered}
          editSchedule={props.editSchedule}
          handleModal={() => setCateringModalState(false)}
          modalState={cateringModalState}
          projectId={props.schedule.projectsFk}
          reloadSchedule={() => setLoadedScheduleInfo(false)}
          schedule={props.schedule}
          scheduleDate={props.schedule.date}
          scheduleId={props.schedule.id}
          scheduleLocations={props.schedule.scheduleLocations}
        />
      )}
      <Card
        style={{
          height: "calc(100vh - 130px)",
          minHeight: 550,
          overflowY: "auto",
        }}
      >
        {props.creatingSchedule ? <NewScheduleTitle /> : <EditScheduleTitle />}
        <CardContent>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ height: "100%" }}
          >
            <Grid container style={{ height: "100%" }}>
              <Grid item xs={5} md={5} lg={5}>
                <div style={{ marginLeft: "10px", maxWidth: "165px" }}>
                  <KeyboardDatePicker
                    disabled={props.project.isArchived === 1}
                    format="dd/MM/yyyy"
                    id="schedule-date-picker"
                    label="Schedule Date *"
                    value={scheduleDate}
                    error={false}
                    helperText={""}
                    minDate={new Date()}
                    onChange={(e) => {
                      checkForCurrentSignedContracts(e);
                      // setScheduleDate(e);
                    }}
                    renderDay={(
                      day,
                      selectedDate,
                      isInCurrentMonth,
                      dayComponent
                    ) => {
                      const date = new Date(day);
                      let isSelected =
                        isInCurrentMonth &&
                        checkForDate(format(date, "yyyy-MM-dd"));
                      return (
                        <Day
                          style={
                            isSelected ? { backgroundColor: "#ed9c91" } : {}
                          }
                        >
                          {dayComponent}
                        </Day>
                      );
                    }}
                  />
                </div>
              </Grid>
              {/* {!props.creatingSchedule && (
                <Grid item xs={7} md={7} lg={7}>
                  {!cateringLoading && (
                    cateringOrder !== null &&
                      (cateringOrder.status === "Accepted") ? (
                        <CateringButton
                          buttonName={cateringOrder.status === "Accepted" ? "Catering Confirmed" : cateringOrder.status}
                          cateringOrder={cateringOrder}
                          editSchedule={props.editSchedule}
                          schedule={props.schedule}
                          onViewOrder={() => setCateringModalState(true)}
                          reloadSchedule={() => setLoadedScheduleInfo(false)}
                          scheduleDate={props.schedule.date}
                          scheduleId={props.schedule.id}
                          scheduleName={props.schedule.name}
                          cancelCatering={() => props.cancelCatering(props.schedule, cateringOrder)}
                        />
                      ) : cateringOrder !== null &&
                        cateringOrder.status === "Invoiced" ? (
                          // <Button
                          //   color="primary"
                          //   onClick={() => setCateringModalState(true)}
                          //   style={{ backgroundColor: "#008000", marginTop: "10px" }}
                          //   variant="contained"
                          // >
                          //   Catering Invoiced
                          // </Button>
                          <CateringButton
                            buttonName={"Catering Invoiced"}
                            cateringOrder={cateringOrder}
                            editSchedule={props.editSchedule}
                            schedule={props.schedule}
                            onViewOrder={() => setCateringModalState(true)}
                            reloadSchedule={() => setLoadedScheduleInfo(false)}
                            scheduleDate={props.schedule.date}
                            scheduleId={props.schedule.id}
                            scheduleName={props.schedule.name}
                            cancelCatering={() => props.cancelCatering(props.schedule, cateringOrder)}
                          />
                        ) : (
                          props.schedule.cateringOrdered === 1 ? (
                            <CateringButton
                              buttonName={"Sent"}
                              cateringOrder={cateringOrder}
                              editSchedule={props.editSchedule}
                              schedule={props.schedule}
                              onViewOrder={() => setCateringModalState(true)}
                              reloadSchedule={() => setLoadedScheduleInfo(false)}
                              scheduleDate={props.schedule.date}
                              scheduleId={props.schedule.id}
                              scheduleName={props.schedule.name}
                              cancelCatering={() => props.cancelCatering(props.schedule, cateringOrder)}
                            />
                          ) 
                          : (
                              <Button
                                color="primary"
                                onClick={() => setCateringModalState(true)}
                                style={{ marginTop: "10px" }}
                                variant="contained"
                                disabled={props.project.isArchived === 1}
                              >
                                {`Order Catering`}
                              </Button>
                            )
                        )
                  )}
                </Grid>
              )} */}
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className={classes.margin}
                  disabled={props.project.isArchived === 1}
                  fullWidth
                  id="schedule-name"
                  label="Name *"
                  margin="normal"
                  onBlur={(e) => {
                    props.editSchedule(
                      "name",
                      e.target.value,
                      props.schedule,
                      1
                    );
                  }}
                  onChange={(e) =>
                    props.editSchedule(
                      "name",
                      e.target.value,
                      props.schedule,
                      0
                    )
                  }
                  placeholder="Enter the schedule's name"
                  size="small"
                  style={{ margin: 8 }}
                  value={props.schedule.name}
                />
              </Grid>
              {!props.loadingSchedule &&
                allScheduleLocations.map((sl, index) => {
                  return (
                    <ScheduleLocation
                      noLocation={noLocation}
                      noLocationAlert={noLocationAlert}
                      changeLocation={changeLocation}
                      promoteScheduleLocation={promoteScheduleLocation}
                      createNewSchedule={createNewSchedule}
                      deleteScheduleLocation={deleteScheduleLocation}
                      editScheduleLocation={editScheduleLocation}
                      epLocation={props.epLocation}
                      getBudget={props.getBudget}
                      handleChangeLocation={props.handleChangeLocation}
                      index={index}
                      key={sl.id}
                      location={sl.locationFkNavigation}
                      project={props.project}
                      schedule={props.schedule}
                      setCreatingSchedule={props.setCreatingSchedule}
                      setShowScheduleTable={props.setShowScheduleTable}
                      setSnackBarProps={props.setSnackBarProps}
                      updateScheduleLocationFee={updateScheduleLocationFee}
                      updateLocationBudget={updateLocationBudget}
                      projectBudgetItems={props.projectBudgetItems}
                      sl={sl}
                      snackbar={props.snackbar}
                      updateLocationContact={props.updateLocationContact}
                      showReschedule={() => {
                        setInvalidSl(true)
                        setInviteesModal(true)
                      }}
                      contractors={contractors}
                    />
                  );
                })}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className={props.project.isArchived === 0 && classes.addLocationButton}
                  disabled={props.project.isArchived === 1}
                  onClick={() => {
                    var index = allScheduleLocations.length - 1;
                    if((allScheduleLocations.length > 0 && allScheduleLocations[index].locationFk !== "blank") || allScheduleLocations.length === 0){
                      addButtonText !== "Five Max" && (props.schedule.name !== null && props.schedule.name.trim() !== "") &&
                        addBlankLocation(
                          props.schedule,
                          props.setSnackBarProps,
                          props.addScheduleLocation
                        );
  
                      if (props.schedule.name === null || props.schedule.name.trim() === "") {
                        setShowScheduleError(true);
                      }
                    }
                  }}
                >
                  {addButtonText}
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </CardContent>
      </Card>
    </div>
  );

  return props.loadingSchedule ? <Loader /> : table;
}

const mapStateToProps = (state, ownProps) => ({
  allScheduleLocations: state.schedules.schedule.scheduleLocations,
  noLocation: state.schedules.noLocation,
  scheduleItems: state.scheduleItems,
  allScheduleItems: state.schedules.schedule.scheduleItem,
  schedule: state.schedules.schedule,
  contractors: state.scheduleItems.contractor,
  projectBudgetItems: state.budget.projectBudgetItems,
});
export default connect(mapStateToProps, {
  changeLocation,
  createNewSchedule,
  deleteScheduleLocation,
  editScheduleLocation,
  noLocationAlert,
  promoteScheduleLocation,
  editScheduleItem,
  updateScheduleLocationFee,
  updateLocationBudget
})(EditSchedule);
