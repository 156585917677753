import React, { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField, Grid, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const autocompleteService = { current: null };

const GooglePlacesEdit = ({
  initialValue,
  countryRestriction = "nz",
  onLocationSelect,
  label = "Select a location",
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const handleSelect = async (value) => {
    if (!value) return;

    try {
      setLoading(true);
      const results = await geocodeByAddress(value.description);
      const latLng = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;
      const country =
        addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name || "";

      onLocationSelect({
        address: value.description,
        latitude: latLng.lat,
        longitude: latLng.lng,
        country,
      });
    } catch (err) {
      setError("Failed to get location details");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, []);

  useEffect(() => {
    let active = true;

    if (!inputValue) {
      setOptions([]);
      return;
    }

    setLoading(true);
    fetch(
      {
        input:
          inputValue /*componentRestrictions: { country: countryRestriction }*/,
      },
      (results) => {
        if (active) {
          setOptions(results || []);
          setLoading(false);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [inputValue, fetch /*countryRestriction*/]);

  return (
    <Autocomplete
      id="google-places-autocomplete"
      style={{flex: "1"}}
      options={options}
      getOptionLabel={(option) => option.description}
      filterOptions={(x) => x}
      loading={loading}
      defaultValue={initialValue}
      onChange={(_, value) => handleSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          onChange={(e) => setInputValue(e.target.value)}
          error={!!error}
          helperText={error}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

GooglePlacesEdit.propTypes = {
  initialValue: PropTypes.shape({
    description: PropTypes.string,
    place_id: PropTypes.string,
    structured_formatting: PropTypes.shape({
      main_text: PropTypes.string,
      secondary_text: PropTypes.string,
      main_text_matched_substrings: PropTypes.arrayOf(
        PropTypes.shape({
          offset: PropTypes.number,
          length: PropTypes.number,
        })
      ),
    }),
  }),
  countryRestriction: PropTypes.string,
  onLocationSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default GooglePlacesEdit;
