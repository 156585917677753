import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepButton,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Previews } from "./DragAndDrop";
import { setSnackBarProps } from "../../../../actions/snackbar";
//import Snacky from ".."
import { AddLocationContact } from "./AddLocationContact";
import GooglePlaces from "./GooglePlaces";
import "./locationStyles.css";
import Snacky from "../../../Shared/Snacky";
import { getCountries, getCurrencies, isEmpty } from "../../../../utils/GeneralUtils";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import GooglePlacesEdit from "./GooglePlacesEdit";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "500px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Location Details", "Location Contacts", "Notes"];
}

function LocationStepper(props) {
  let { snackbar, setSnackBarProps } = props;
  const classes = useStyles();
  const [location, setLocation] = useState({});
  const [stepperLocation, setStepperLocation] = useState({});
  const [indoor, setIndoor] = useState(false);
  const [outdoor, setOutdoor] = useState(false);
  const [stepperFiles, setStepperFiles] = useState([]);
  const [stepperContact, setStepperContact] = useState(null);
  const [stepperTypes, setStepperTypes] = useState([0, 0, 0]);
  const [locationNotes, setLocationNotes] = useState({
    floorType: "",
    roofHeight: "",
    recceStatus: "",
    videosLink: "",
    floorPlanLink: "",
    windowsNotes: "",
    parkingNotes: "",
    furnitureNotes: "",
    sunNotes: "",
    lightNotes: "",
    specialNotes: "",
    evacuationProcedure: "",
    accessDetails: "",
  });
  const {
    floorType,
    roofHeight,
    recceStatus,
    videosLink,
    floorPlanLink,
    windowsNotes,
    parkingNotes,
    furnitureNotes,
    sunNotes,
    lightNotes,
    specialNotes,
    evacuationProcedure,
    accessDetails,
  } = locationNotes;
  const [showLoader, setShowLoader] = useState(false);
  const [pricing, setPricing] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [country, setCountry] = useState({ name: "New Zealand", code: "nz" });
  const [skipped, setSkipped] = useState(new Set());
  const [googleAddress, setGoogleAddress] = useState("");
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const steps = getSteps();

  useEffect(() => {
    if (!isEmpty(location?.address)) {
      let rawAddress = location.address;
      let res = rawAddress.replace(/ /g, "%20");
      res = res.replace(/,/g, "%2C");
      res = res.replace(/|/, "%7C");
      setGoogleAddress(res);
    }
  }, [location?.address]);

  const passFilesToStepper = (newFiles) => {
    setStepperFiles(newFiles);
    props.passImagesUp(newFiles);
  };
  const passContactToStepper = (contact) => {
    if (contact !== undefined) {
      setStepperContact(contact);
    }
  };
  const totalSteps = () => {
    return getSteps().length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (activeStep === 0 && location.address === undefined) {
      setSnackBarProps("warning", "Please add address", true);
    } else {
      const newActiveStep = isLastStep()
        ? // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;
      const newCompleted = new Set(completed);
      newCompleted.add(activeStep);
      setCompleted(newCompleted);
      setActiveStep(newActiveStep);
    }
    if (activeStep === 2) {
      saveLocationToDb();
    }
  };
  useEffect(() => {
    props.passStepUp(activeStep);
  }, [activeStep]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  const passLocationToStepper = (locationFromGooglePlaces) => {
    setStepperLocation(locationFromGooglePlaces);
  };

  const handlePricingChange = (event) => {
    setPricing(event.target.value);
  };
  const handleLocationNotesChange = (note) => (event) => {
    setLocationNotes({ ...locationNotes, [note]: event.target.value });
  };
  const handleLocationChange = (locationInfo) => {
    setLocation(locationInfo);
  };

  const handleSaveImages = (responseData) => {
    var formData = new FormData();
    console.log(stepperFiles);

    stepperFiles.map((file) => {
      formData.append("file", file);
    });

    formData.append("locationId", responseData.id);
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    Axios.post("/CloudStorage/UploadImages", formData, {
      headers: { "content-type": "multipart/form-data" },
    });
  };

  const saveLocationToDb = () => {
    setShowLoader(true);

    var indoors, outdoors, price;
    if (outdoor) {
      outdoors = 1;
    } else {
      outdoors = 0;
    }
    if (indoor) {
      indoors = 1;
    } else {
      indoors = 0;
    }
    console.log(outdoors);
    console.log(indoors);
    try {
      price = parseFloat(pricing);
    } catch (e) {
      console.log(e);
      price = null;
    }
    console.log("creating location");

    Axios.post("Locations/PostLocation", {
      address: location.address,
      latitude: location.latitude,
      longitude: location.longitude,
      country: location.country,
      floorType: locationNotes.floorType,
      recceStatus: locationNotes.recceStatus,
      videosLink: locationNotes.videosLink,
      floorPlanLink: locationNotes.floorPlanLink,
      windowsNotes: locationNotes.windowsNotes,
      parkingNotes: locationNotes.parkingNotes,
      furnitureNotes: locationNotes.furnitureNotes,
      sunNotes: locationNotes.sunNotes,
      lightNotes: locationNotes.lightNotes,
      specialNotes: locationNotes.specialNotes,
      evacuationProcedure: locationNotes.evacuationProcedure,
      accessDetails: locationNotes.accessDetails,
      primaryContact: stepperContact !== null ? stepperContact.id : null,
      isOutdoor: outdoors,
      isIndoor: indoors,
      pricing: price,
    })
      .then((response) => {
        handleSaveImages(response.data);
        props.closeModal();
        props.reloadLocations();
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  const onLocationSelect = (newLocationDetails) => {
    console.log(newLocationDetails);
    setLocation({
      ...location,
      address: newLocationDetails?.address,
      country: newLocationDetails?.country,
      latitude: newLocationDetails?.latitude,
      longitude: newLocationDetails?.longitude,
    });
  };

  return (
    <div className={classes.root}>
      <Snacky snackprops={snackbar} setSnackbarProps={setSnackBarProps} />
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <div>
            {activeStep === 0 && (
              <div style={{ minHeight: "60vh" }}>
                <div style={{ display: "flex", gap: 10 }}>
                  <GooglePlacesEdit
                    initialValue={location?.address}
                    onLocationSelect={onLocationSelect}
                  />
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onClick={(event) => {
                            if (event.target.checked) {
                              setIndoor(true);
                            } else {
                              setIndoor(false);
                            }
                          }}
                          checked={indoor}
                          value={indoor}
                        />
                      }
                      label="Indoor"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onClick={(event) => {
                            if (event.target.checked) {
                              setOutdoor(true);
                            } else {
                              setOutdoor(false);
                            }
                          }}
                          checked={outdoor}
                          value={outdoor}
                        />
                      }
                      label="Outdoor"
                    />
                  </FormGroup>

                  <TextField
                    label="Location Pricing"
                    variant="outlined"
                    value={pricing}
                    onChange={handlePricingChange}
                  />
                </div>

                <Grid container style={{alignItems: "center", paddingTop: 15}} spacing={2}>
                  <Grid item xs={4} md={4} lg={4}>
                    {!iframeLoaded && (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="40vH"
                        animation="wave"
                        style={{ flex: 1 }}
                      />
                    )}
                    <iframe
                      style={{
                        border: "none",
                        height: "40vH",
                        flex: 1,
                        display: iframeLoaded ? "block" : "none",
                      }}
                      src={
                        googleAddress
                          ? `https://maps.google.com/maps?q='${googleAddress}'&t=y&z=16&output=embed`
                          : ""
                      }
                      width="100%"
                      height="100%"
                      onLoad={() => setIframeLoaded(true)}
                    ></iframe>
                  </Grid>
                  <Grid item xs={8} md={8} lg={8}>
                    <Previews
                      passFilesToStepper={passFilesToStepper}
                      stepperImages={stepperFiles}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {activeStep === 1 && (
              <div
                style={{
                  minHeight: "60vh",
                  textAlign: "center",
                  marginTop: "35px",
                }}
              >
                <AddLocationContact
                  passContactToStepper={passContactToStepper}
                  contactFromStepper={stepperContact}
                  typesFromStepper={stepperTypes}
                />
              </div>
            )}
            {activeStep === 2 && (
              <Grid container>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Floor Type"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={floorType}
                    size="small"
                    onChange={handleLocationNotesChange("floorType")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Roof Height"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={roofHeight}
                    size="small"
                    onChange={handleLocationNotesChange("roofHeight")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Window Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={windowsNotes}
                    size="small"
                    onChange={handleLocationNotesChange("windowsNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Parking Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={parkingNotes}
                    size="small"
                    onChange={handleLocationNotesChange("parkingNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Furniture Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    size="small"
                    value={furnitureNotes}
                    onChange={handleLocationNotesChange("furnitureNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Sun Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={sunNotes}
                    size="small"
                    onChange={handleLocationNotesChange("sunNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Light Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={lightNotes}
                    size="small"
                    onChange={handleLocationNotesChange("lightNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Special Notes"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={specialNotes}
                    size="small"
                    onChange={handleLocationNotesChange("specialNotes")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Evacuation Procedure"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={evacuationProcedure}
                    size="small"
                    onChange={handleLocationNotesChange("evacuationProcedure")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Access Details"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={accessDetails}
                    size="small"
                    onChange={handleLocationNotesChange("accessDetails")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Recce Status"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={recceStatus}
                    size="small"
                    onChange={handleLocationNotesChange("recceStatus")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Video Links"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={videosLink}
                    size="small"
                    onChange={handleLocationNotesChange("videosLink")}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "10px" }}
                >
                  <TextField
                    label="Floor Plan Links"
                    multiline={true}
                    variant="outlined"
                    style={{ width: "90%" }}
                    value={floorPlanLink}
                    size="small"
                    onChange={handleLocationNotesChange("floorPlanLink")}
                  />
                </Grid>
              </Grid>
            )}
            <div align="right">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === 2 ? "Finish" : "Next"}
              </Button>
              <CircularProgress
                size={30}
                style={showLoader ? { marginLeft: "5px" } : { display: "none" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  setSnackBarProps,
})(LocationStepper);
