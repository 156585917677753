import React, { useEffect, useState } from "react";
import { getProject, resetProject } from "../../actions/schedules";
import { connect } from "react-redux";
import ProjectHeader from "./ProjectHeader/ProjectHeader";
import Header from "../Header";
import Loader from "../Loader";
import { useLocation } from "react-router-dom";
import { getBudget, resetBudget, getTasks, getProjectBudgetItems } from "../../actions/budget";

const Home = ({
  getProject,
  resetProject,
  project,
  loading,
  getBudget,
  getProjectBudgetItems,
  getTasks,
}) => {
  var location = useLocation();
  const [readyToLoadProject, setReadyToLoadProject] = useState(false)

  useEffect(() => {
    if (project?.id != location.pathname.split("/")[3] && readyToLoadProject) {
      console.log("Home Project")
      getProject(location.pathname.split("/")[3]);
      getBudget(location.pathname.split("/")[3]);
      getProjectBudgetItems(location.pathname.split("/")[3]);
      getTasks(location.pathname.split("/")[3]);
    }
  }, [location.pathname, project]);

  useEffect(() => {
    console.log("Home Reset")
    resetProject();
    resetBudget();
    setReadyToLoadProject(true)
  }, []);

  if (!loading) {
    return (
      <div>
        <Header project={project} resetProject={resetProject} />
        {/* <ProjectHeaderTest /> */}
        <ProjectHeader />
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <Loader />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  project: state.schedules.project,
  loading: state.schedules.loadingProject,
});
// https://react-redux.js.org/api/connect
export default connect(mapStateToProps, {
  getProject,
  resetProject,
  getBudget,
  getProjectBudgetItems,
  getTasks,
  resetBudget,
})(Home);
