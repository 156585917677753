import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "../Admin.css";

const InfluencerAdminTemplate = ({
    docFileId,
    title
}) => {

    return (
        <main >
            <div className="main-content" />
            <Typography variant="h4">{title}</Typography>
            <div style={{ height: "80vh" }}>
                <iframe
                    src={`https://docs.google.com/document/d/${docFileId}/edit`}
                    className={
                        "custom-payable-table-row-details-file-container-pdf-iframe "
                    }
                    width={"100%"}
                    height={"100%"}
                    title={`document-image-${docFileId}`}
                ></iframe>
            </div>
        </main>
    )
}

export default InfluencerAdminTemplate;