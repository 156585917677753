import React, { useState, useEffect } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  FormControl,
  Grid,
  TextField,
  CircularProgress,
  Snackbar,
  Switch,
  FormLabel,
  Fab,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddAgentPopUp from "./AddAgentPopUp";
import { checkEmail } from "../../../../utils/Validation";
import { setDialogProps } from "../../../../actions/dialog";
import InformationDialogy from "../../../Shared/InformationDialogy";
import "../Location/locationStyles.css";
import { useAuth } from "../../../../context/auth/AuthContext";
import CurrencyDropdownMenu from "../../../CurrencyExchange/CurrencyDropdownMenu";
import { getCurrencies } from "../../../../utils/GeneralUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //marginTop: "30px"
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addAgentFab: {
    background: "#00b2e2",
    color: "white",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "0px",
    marginTop: "auto",
    minWidth: "40px",
    maxWidth: "40px",
    "&:hover": {
      background: "#008cb3",
    },
  },
}));

const StyledButton = withStyles(() => ({
  root: {
    marginLeft: "auto",
    background: "#00b2e2",
    color: "white",
    "&:hover": {
      background: "#008cb3",
    },
  },
}))(Button);

const AddTalentContact = (props) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [showLoader, setShowLoader] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);
  const [failedCreate, setFailedCreate] = useState(false);
  const [showAddAgentContact, setShowAddAgentContact] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [requiredInfo, setRequiredInfo] = useState(
    props.goAhead === true
      ? {
          firstName: props.goAheadContact.firstName,
          lastName: props.goAheadContact.lastName,
          emailAddress: props.goAheadContact.emailAddress,
          phoneNumber: props.goAheadContact.phoneNumber,
          hasAgent: props.goAheadContact.hasAgent,
          hasParent: props.goAheadContact.hasParent,
          parentName: props.goAheadContact.parentName,
          currency: props.goAheadContact.currency || "NZD",
        }
      : {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          hasAgent: 0,
          hasParent: 0,
          parentName: "",
          currency: "NZD",
        }
  );
  const [agent, setAgent] = useState(
    props.goAhead === true ? props.goAheadContact.agent : null
  );
  const [getAgents, setGetAgents] = useState(true);

  let [agentList, setAgentList] = useState([]);
  const closeSuccessCreateSnackbar = () => {
    setSuccessCreate(false);
  };

  const closeFailedCreateSnackbar = () => {
    setFailedCreate(false);
  };

  const handleRequiredInfoChange = (field) => (event) => {
    console.log(field);
    let otherField = "";
    let otherFieldValue = 0;
    var value = event.target.value;
    if (field === "hasAgent") {
      if (event.target.checked) {
        value = 1;
        otherField = "hasParent";
        otherFieldValue = 0;
      } else {
        value = 0;
      }
    } else if (field === "hasParent") {
      if (event.target.checked) {
        value = 1;
        otherField = "hasAgent";
        otherFieldValue = 0;
      } else {
        value = 0;
      }
    }
    if (field === "emailAddress") {
      setRequiredInfo({ ...requiredInfo, [field]: value.trim() });
    } else {
      setRequiredInfo({
        ...requiredInfo,
        [field]: value,
        [otherField]: otherFieldValue,
      });
    }
  };

  
  const handleSaveTalent = () => {
    if (checkForDuplicates()) {
      setShowLoader(true);
      Axios.post(`People/PostPerson?user=${user?.email}`, {
        firstName: requiredInfo.firstName.trim(),
        lastName: requiredInfo.lastName.trim(),
        fullName: `${requiredInfo.firstName.trim()} ${requiredInfo.lastName.trim()}`,
        emailAddress: requiredInfo.emailAddress,
        phoneNumber: requiredInfo.phoneNumber,
        agentFk: agent !== null && agent !== undefined ? agent.id : null,
        personCategory: "Talent",
        organisationFk: 1,
        currency: requiredInfo.currency || "NZD",
        hasParent: requiredInfo.hasParent,
        parentName:
          requiredInfo.hasParent === 1 ? requiredInfo.parentName.trim() : null,
      })
        .then((res) => {
          //props.handleCloseModal();

          if (props.parent === "NewScheduleItem") {
            props.setGoAhead(false);
            props.setNewContact([]);
            props.addContactAndCloseModal(res.data);
          }
          setSuccessCreate(true);
          setRequiredInfo({
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
            hasAgent: 0,
            currency: "NZD",
          });
          setAgent(null);

          console.log(res);
          setShowLoader(false);
          setSuccessCreate(true);
        })
        .catch((e) => {
          console.log(e);
          setShowLoader(false);
          setFailedCreate(true);
        });
    }
  };

  const isObjectEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  };

  const validateEmail = () => {
    var requiredFields = requiredInfo;
    if (requiredFields.hasAgent === 0) {
      return checkEmail(requiredFields.emailAddress); //error
    } else {
      return false; //No error
    }
  };

  const checkRequiredFields = () => {
    var requiredFields = requiredInfo;
    if (
      requiredFields.firstName !== "" &&
      requiredFields.firstName !== null &&
      requiredFields.lastName !== "" &&
      requiredFields.lastName !== null &&
      (requiredFields.emailAddress == "" ||
        requiredFields.emailAddress == null ||
        !checkEmail(requiredFields.emailAddress))
    ) {
      if (requiredFields.hasAgent === 0 && requiredFields.hasParent === 0) {
        if (
          requiredFields.phoneNumber !== "" &&
          requiredFields.phoneNumber !== null &&
          requiredFields.emailAddress !== "" &&
          requiredFields.emailAddress !== null
        ) {
          return true;
        }
      } else if (
        requiredFields.hasAgent === 1 &&
        agent !== null &&
        !isObjectEmpty(agent)
      ) {
        return true;
      } else if (requiredFields.hasParent === 1) {
        if (
          requiredFields.phoneNumber !== "" &&
          requiredFields.phoneNumber !== null &&
          requiredFields.emailAddress !== "" &&
          requiredFields.emailAddress !== null &&
          requiredFields.parentName !== null &&
          requiredFields.parentName !== ""
        ) {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const changeAgent = (object, value) => {
    // setAgent(value);
    if (value !== null) {
      setAgent(value);
    }
  };

  const saveNewAgent = (agent) => {
    console.log(agent);
    Axios.post(`Agents/PostAgent`, agent)
      .then((res) => {
        console.log(res.data);
        setAgent(res.data);
        setGetAgents(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //-----------------------------------------------
  const checkForDuplicates = () => {
    if (!props.allPeople) {
      return true;
    } else if (props.goAhead === true) {
      return true;
    } else {
      let duplicatesArr = [];
      props.allPeople.forEach((person) => {
        if (
          person.firstName == requiredInfo.firstName.toLowerCase() &&
          person.lastName == requiredInfo.lastName.toLowerCase()
        ) {
          duplicatesArr.push(person);
        } else if (person.emailAddress) {
          if (person.emailAddress == requiredInfo.emailAddress.toLowerCase()) {
            if (requiredInfo.hasAgent === 0) {
              duplicatesArr.push(person);
            }
          }
        } else if (person.phoneNumber) {
          if (person.phoneNumber == requiredInfo.phoneNumber) {
            if (requiredInfo.hasAgent == 0) {
              duplicatesArr.push(person);
            }
          }
        }
      });
      if (duplicatesArr.length == 0) {
        return true;
      } else {
        props.setDuplicates(duplicatesArr);
        props.setNewContact(requiredInfo);
        return false;
      }
    }
  };

  useEffect(() => {
    console.log(props.parent);
    if (props.goAhead) {
      handleSaveTalent();
    }
  }, []);
  //-----------------------------------------------

  useEffect(() => {
    if (getAgents) {
      Axios.get(`Agents/GetAgents`, { params: { organisationId: 1 } })
        .then((res) => {
          console.log(res.data);
          setAgentList(res.data);
          setGetAgents(false);
        })
        .catch((e) => {
          console.log(e);
          setGetAgents(false);
        });
    }
  });

  var snackbarSuccess = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={successCreate}
      autoHideDuration={2000}
      onClose={closeSuccessCreateSnackbar}
    >
      <MuiAlert severity="success">Talent Created Successfully!</MuiAlert>
    </Snackbar>
  );

  var snackbarFailed = (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={failedCreate}
      autoHideDuration={2000}
      onClose={closeFailedCreateSnackbar}
    >
      <MuiAlert severity="error">Failed to Create Talent!</MuiAlert>
    </Snackbar>
  );

  return (
    <div className={classes.root}>
      {showWarning ? (
        <InformationDialogy
          setDialogProps={setDialogProps}
          buttonProps={{
            show: showWarning,
            titleText: "Invalid data",
            contentText:
              "One of the fields is incorrect. Please fix this before continuing",
            buttonColor: "primary",
            buttonText: "Ok",
          }}
          handleButton={() => setShowWarning(false)}
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              required
              variant="outlined"
              style={{ width: "100%" }}
              id="fname-input"
              label="First Name"
              size="small"
              value={requiredInfo.firstName}
              onChange={handleRequiredInfoChange("firstName")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              required
              variant="outlined"
              style={{ width: "100%" }}
              id="lname-input"
              label="Last Name"
              size="small"
              value={requiredInfo.lastName}
              onChange={handleRequiredInfoChange("lastName")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ display: "flex", alignItems: "center" }}>
          {requiredInfo.firstName !== "" && requiredInfo.lastName !== "" ? (
            <>
              <div>
                <CurrencyDropdownMenu
                  size={"small"}
                  originalCurrency={requiredInfo.currency}
                  handleChangeCurrency={(currencyObj) =>
                    setRequiredInfo({
                      ...requiredInfo,
                      currency: currencyObj.value,
                    })
                  }
                  selectedCurrencies={getCurrencies()}
                  // selectedCurrencies={getCurrencies().filter(
                  //   (c) => c.value === "NZD" || c.value === "AUD"
                  // )}
                />
              </div>
              <div style={{ flex: "1" }}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      textAlign: "right",
                    }}
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        color: "#2d2d2d",
                      }}
                    >
                      <Typography variant="caption">Has Agent: </Typography>
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    style={
                      requiredInfo.hasAgent === 1
                        ? { color: "grey" }
                        : { borderBottom: "solid", borderColor: "#3f51b5" }
                    }
                  >
                    <Typography variant="caption">No</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      color="primary"
                      checked={requiredInfo.hasAgent === 1 ? true : false}
                      name="bcOrIc"
                      onChange={handleRequiredInfoChange("hasAgent")}
                      className={"blueSwitch"}
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    style={
                      requiredInfo.hasAgent === 1
                        ? { borderBottom: "solid", borderColor: "#3f51b5" }
                        : { color: "grey" }
                    }
                  >
                    <Typography variant="caption">Yes</Typography>
                  </Grid>
                </Grid>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      textAlign: "right",
                    }}
                  >
                    <FormLabel
                      component="legend"
                      style={{
                        color: "#2d2d2d",
                      }}
                    >
                      <Typography variant="caption">Has Parent: </Typography>
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    style={
                      requiredInfo.hasParent === 1
                        ? { color: "grey" }
                        : { borderBottom: "solid", borderColor: "#3f51b5" }
                    }
                  >
                    <Typography variant="caption">No</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      color="primary"
                      checked={requiredInfo.hasParent === 1 ? true : false}
                      name="bcOrIc"
                      onChange={handleRequiredInfoChange("hasParent")}
                      className={"blueSwitch"}
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    style={
                      requiredInfo.hasParent === 1
                        ? { borderBottom: "solid", borderColor: "#3f51b5" }
                        : { color: "grey" }
                    }
                  >
                    <Typography variant="caption">Yes</Typography>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} spacing={1}>
          {requiredInfo.hasAgent === 0 &&
          requiredInfo.hasParent === 0 &&
          requiredInfo.firstName !== "" &&
          requiredInfo.lastName !== "" ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    id="phone-input"
                    label="Phone"
                    value={requiredInfo.phoneNumber}
                    onChange={handleRequiredInfoChange("phoneNumber")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    required
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    id="email-input"
                    error={checkEmail(requiredInfo.emailAddress)}
                    helperText={
                      checkEmail(requiredInfo.emailAddress)
                        ? "Not a valid email address"
                        : null
                    }
                    label="Email"
                    value={requiredInfo.emailAddress}
                    onChange={handleRequiredInfoChange("emailAddress")}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : requiredInfo.hasParent === 1 &&
            requiredInfo.firstName !== "" &&
            requiredInfo.lastName !== "" ? (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      style={{ width: "100%" }}
                      id="parent-input"
                      label="Parent's Full Name"
                      value={requiredInfo.parentName}
                      onChange={handleRequiredInfoChange("parentName")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      style={{ width: "100%" }}
                      id="phone-input"
                      label="Phone"
                      value={requiredInfo.phoneNumber}
                      onChange={handleRequiredInfoChange("phoneNumber")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormControl style={{ width: "100%" }}>
                    <TextField
                      required
                      size="small"
                      variant="outlined"
                      style={{ width: "100%" }}
                      id="email-input"
                      label="Email"
                      error={checkEmail(requiredInfo.emailAddress)}
                      helperText={
                        checkEmail(requiredInfo.emailAddress)
                          ? "Not a valid email address"
                          : null
                      }
                      value={requiredInfo.emailAddress}
                      onChange={handleRequiredInfoChange("emailAddress")}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          ) : requiredInfo.hasAgent === 1 &&
            requiredInfo.firstName !== "" &&
            requiredInfo.lastName !== "" ? (
            <Grid container spacing={1}>
              <Grid item xs={11} md={11} lg={11}>
                <Autocomplete
                  clearOnEscape
                  options={agentList}
                  getOptionLabel={(option) =>
                    option.companyFkNavigation !== null
                      ? `${option.firstName} ${option.lastName} - ${option.companyFkNavigation.name}`
                      : `${option.firstName} ${option.lastName} - No Company`
                  }
                  value={agent}
                  style={{
                    width: "auto",
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Agent *"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )}
                  onChange={changeAgent}
                />
              </Grid>
              <Grid item xs={1} md={1} lg={1}>
                <Fab
                  size="small"
                  className={classes.addAgentFab}
                  onClick={() => {
                    setShowAddAgentContact(true);
                    props.addAgentPopUpOpened();
                  }}
                >
                  <PersonAddIcon />
                </Fab>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    id="phone-input"
                    label="Talent Phone (Optional)"
                    value={requiredInfo.phoneNumber}
                    onChange={handleRequiredInfoChange("phoneNumber")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    id="email-input"
                    label="Talent Email (Optional)"
                    value={requiredInfo.emailAddress}
                    onChange={handleRequiredInfoChange("emailAddress")}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <FormControl style={{ marginTop: "10px", marginRight: "10px" }}>
          <CircularProgress
            size={30}
            style={showLoader ? {} : { display: "none" }}
          />
        </FormControl>
      </Grid>
      <div
        style={
          checkRequiredFields()
            ? { display: "flex", justifyContent: "flex-end" }
            : { display: "none" }
        }
      >
        <FormControl style={{ marginTop: "10px" }}>
          <StyledButton
            onClick={() => {
              validateEmail() ? setShowWarning(true) : handleSaveTalent();
            }}
          >
            Submit
          </StyledButton>
        </FormControl>
      </div>
      {snackbarSuccess}
      {snackbarFailed}
      {showAddAgentContact ? (
        <AddAgentPopUp
          modalState={showAddAgentContact}
          handleModal={() => {
            setShowAddAgentContact(false);
            props.addAgentPopUpClosed();
          }}
          organisationId={1}
          handleSave={saveNewAgent}
          parent={"AddTalentContact"}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

// export default AddTalentContact;

const mapStateToProps = (state, ownProps) => ({
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  setDialogProps,
})(AddTalentContact);
