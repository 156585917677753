import React, { Fragment, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ClientBudget from "./ClientBudget";
import "./clientTabs.scss";
import NewCampaignButton from "./NewCampaignButton";
import CampaignsTable from "./CampaignsTable/CampaignsTable";
import ClientCampaign from "./Campaign/ClientCampaign";
import ClientMediaSpend from "./Media/ClientMediaSpend";
import NewMediaSpendButton from "./Media/NewMediaSpendButton";
import ClientPurchaseOrder from "./PurchaseOrder/ClientPurchaseOrder";
import ClientPeopleOfInfluence from "./PeopleOfInfluence/ClientPeopleOfInfluence";
import BudgetTransferPopup from "./BudgetTransfer/BudgetTransferPopup";
import AddBudgetPopup from "./Budget/AddBudgetPopup";
import Budgets from "./Budget/Budgets";
import MasterStatusButton from "./MasterStatus/MasterStatusButton";
import Heading from "../Shared/Heading/Heading";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    color: "black",
  },
  indicator: {
    backgroundColor: "black",
  },
}));

const ClientTabs = ({
  company,
  value,
  handleChange,
  budget,
  isLoadingBudget,
  fetchAllBudget,
  preLoadCampaignById,
  handlePreLoadCampaign,
  updateHiddenStatus,
  hasMediaSpend,
  hasInfluencerSpend,
  hasPoiSpend,
  handleCloseProject,
  handleCloseAndTransfer,
  handleCloseProjectWithLiability,
  setSnackBarProps,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showBudgetTransferPopup, setShowBudgetTransferPopup] = useState(false);
  const [showAddBudgetPopup, setShowAddBudgetPopup] = useState(false);
  const [newBudgetType, setNewBudgetType] = useState(null);

  const classes = useStyles();

  const handleSelectCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    // Update URL without reloading entire page
    window.history.replaceState(
      null,
      "Production Core",
      `/Client/Home/${company.id}/${value}/${campaign?.id ? campaign?.id : ""}`
    );
  };

  const handleGoBackClick = () => {
    handlePreLoadCampaign(null); // remove campaignId to prevent looping in the same campaing after campaigns load
    window.history.replaceState(
      null,
      "Production Core",
      `/Client/Home/${company.id}/${value}`
    );
    setSelectedCampaign(null);
  };

  const handleCreateNewCampaign = () => {
    setSelectedCampaign({
      name: null,
      socialHashtags: null,
      competitors: null,
      callToAction: null,
      companyFk: company.id,
    });
  };

  const getAddBudgetButton = (budgetType) => {
    return (
      <div className="add-budget-button-container">
        <Button
          className="default-button add-budget-button"
          onClick={() => {
            setNewBudgetType(budgetType);
            setShowAddBudgetPopup(true);
          }}
        >
          Add Budget
        </Button>
      </div>
    );
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          classes={{
            indicator: classes.indicator,
            root: classes.root,
          }}
        >
          <Tab
            label="People Of Influence"
            className={
              !hasPoiSpend && company?.organisationFk !== 5
                ? "client-tabs-display-none"
                : ""
            }
          />
          <Tab
            label="Influencer"
            className={
              !hasInfluencerSpend &&
              (company?.organisationFk === 5 || company?.organisationFk === 1)
                ? "client-tabs-display-none"
                : ""
            }
          />
          <Tab
            label="Media"
            className={!hasMediaSpend ? "client-tabs-display-none" : ""}
          />
          <Tab label="Purchase Order" />
        </Tabs>
      </Paper>
      {showBudgetTransferPopup && (
        <BudgetTransferPopup
          handleClose={() => {
            setShowBudgetTransferPopup(false);
          }}
          open={showBudgetTransferPopup}
          defaultClientId={company?.id}
        />
      )}
      {showAddBudgetPopup && newBudgetType && (
        <AddBudgetPopup
          open={showAddBudgetPopup}
          handleClose={() => {
            setShowAddBudgetPopup(false);
            setNewBudgetType(null);
          }}
          handleSaveCallback={fetchAllBudget}
          setSnackBarProps={setSnackBarProps}
          company={company}
          newBudgetType={newBudgetType}
          budgetItems={budget?.filter((b) =>
            b.category.includes(newBudgetType)
          )}
        />
      )}
      {company && (
        <Container maxWidth={"lg"}>
          <div className="client-tabs-root-container">
            <Heading title={company?.name ? company?.name : ""} />
            <TabPanel value={value} index={0}>
              <div className="client-tabs-main-container">
                <Budgets
                  handleCloseAndTransfer={handleCloseAndTransfer}
                  handleCloseProjectWithLiability={
                    handleCloseProjectWithLiability
                  }
                  handleCloseProject={handleCloseProject}
                  budget={budget}
                  updateHiddenStatus={updateHiddenStatus}
                  handlePreLoadCampaign={handlePreLoadCampaign}
                  isLoadingBudget={isLoadingBudget}
                />
                <ClientPeopleOfInfluence
                  budgetItems={budget}
                  companyId={company?.id}
                  fetchAllBudget={fetchAllBudget}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="client-tabs-main-container">
                <Budgets
                  handleCloseAndTransfer={handleCloseAndTransfer}
                  handleCloseProjectWithLiability={
                    handleCloseProjectWithLiability
                  }
                  handleCloseProject={handleCloseProject}
                  // budget={budget}
                  budget={budget?.filter((b) =>
                    b.category.includes("Influencer Spend") ||
                    b.category.includes("Influencer/Content Creator")
                  )}
                  updateHiddenStatus={updateHiddenStatus}
                  handlePreLoadCampaign={handlePreLoadCampaign}
                  isLoadingBudget={isLoadingBudget}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <MasterStatusButton />
                </div>
                {selectedCampaign ? (
                  <ClientCampaign
                    selectedCampaign={selectedCampaign}
                    handleGoBackClick={handleGoBackClick}
                    budgetItems={budget}
                    fetchAllBudget={fetchAllBudget}
                  />
                ) : (
                  <>
                    {/* <Button
                      onClick={() => setShowBudgetTransferPopup(true)}
                      className="default-button-grey roboto-regular"
                      size="small"
                    >
                      Transfer Budget
                    </Button> */}
                    <NewCampaignButton handleClick={handleCreateNewCampaign} />
                    <CampaignsTable
                      companyId={company?.id}
                      handleSelectCampaign={handleSelectCampaign}
                      preLoadCampaignById={preLoadCampaignById}
                    />
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="client-tabs-main-container">
                {getAddBudgetButton("Media Spend")}
                <Budgets
                  handleCloseAndTransfer={handleCloseAndTransfer}
                  handleCloseProjectWithLiability={
                    handleCloseProjectWithLiability
                  }
                  handleCloseProject={handleCloseProject}
                  budget={budget?.filter((b) =>
                    b.category.includes("Media Spend")
                  )}
                  isMedia={true}
                  updateHiddenStatus={updateHiddenStatus}
                  handlePreLoadCampaign={handlePreLoadCampaign}
                  isLoadingBudget={isLoadingBudget}
                />
                <ClientMediaSpend
                  budgetItems={budget?.filter((b) =>
                    b.category.includes("Media Spend")
                  )}
                  companyId={company?.id}
                  company={company}
                  organisationId={company?.organisationFk}
                  fetchAllBudget={fetchAllBudget}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className="client-tabs-main-container">
                <Budgets
                  handleCloseAndTransfer={handleCloseAndTransfer}
                  handleCloseProjectWithLiability={
                    handleCloseProjectWithLiability
                  }
                  handleCloseProject={handleCloseProject}
                  budget={budget}
                  isMedia={true}
                  updateHiddenStatus={updateHiddenStatus}
                  handlePreLoadCampaign={handlePreLoadCampaign}
                  isLoadingBudget={isLoadingBudget}
                />
                <ClientPurchaseOrder
                  budgetItems={budget}
                  companyId={company?.id}
                  baseCurrency={company?.currency || "NZD"}
                  fetchAllBudget={fetchAllBudget}
                />
              </div>
            </TabPanel>
          </div>
        </Container>
      )}
    </>
  );
};

export default ClientTabs;
