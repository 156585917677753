import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import ClientTabs from "./ClientTabs";
import "./client.scss";
import Snacky from "../Shared/Snacky";
import { useAuth } from "../../context/auth/AuthContext";
import { useUserContext } from "../../context/user/UserContext";

const Client = () => {
  const { user } = useAuth();

  const { id, tabValue, campaignId } = useParams();
  const [company, setCompany] = useState(null);
  const [budget, setBudget] = useState([]);
  const [isLoadingBudget, setIsLoadingBudget] = useState(true);
  const [projects, setProjects] = useState([]);
  const [hasMediaSpend, setHasMediaSpend] = useState(false);
  const [hasPoiSpend, setHasPoiSpend] = useState(false);
  const [hasInfluencerSpend, setHasInfluencerSpend] = useState(false);
  const [activeTab, setActiveTab] = useState(tabValue ? Number(tabValue) : 3);
  const [preLoadCampaignById, setPreLoadCampaignById] = useState(
    campaignId ? Number(campaignId) : 0
  );
  const [snackBarProps, setSnackBarProps] = useState({
    open: false,
    severity: "success",
    text: "",
  });

  useEffect(() => {
    if (id) {
      fetchCompanyDetail();
      fetchAllBudget();
    }
  }, []);

  const fetchCompanyDetail = () => {
    axios.get(`Companies/GetCompany/${id}`).then(({ data }) => {
      setCompany(data);
    });
  };

  const fetchAllBudget = () => {
    axios
      .get(`budgetitems/GetBudgetForCompanyByProject/${id}`, {
        params: { isArchived: 0 },
      })
      .then(({ data }) => {
        setBudget(data);
        setHasMediaSpend(
          !!data?.find((b) => b.category.includes("Media Spend"))
        );
        setHasPoiSpend(
          !!data?.find((b) => b.category.includes("POI Spend"))
        );
        setHasInfluencerSpend(
          !!data?.find((b) => b.category.includes("Influencer Spend") || b.category.includes("Influencer/Content Creator"))
        );
        setIsLoadingBudget(false);
      });
  };

  const handlePreLoadCampaign = (_campaignId) => {
    window.history.replaceState(
      null,
      "Production Core",
      `/Client/Home/${id}/${activeTab}/${_campaignId ? _campaignId : ""}`
    );
    setPreLoadCampaignById(_campaignId);
  };

  const updateHiddenStatus = (budgetId, hiddenStatus) => {  
    setBudget(
      [...budget].map((b) =>
        b.budgetId === budgetId ? { ...b, isHidden: hiddenStatus } : b
      )
    );
  };

  const handleCloseProject = (projectId) => {
    axios.delete(`Projects/ArchiveThompsonSpencerProject/${projectId}`, {
      params: { user: user?.email }
    })
      .then(() => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Project has been archived.",
        });
        setIsLoadingBudget(true);
        fetchAllBudget();
      }).catch(e => {
        console.log(e)
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to archive project.",
        });
      })
  }

  const handleCloseProjectWithLiability = (projectId) => {
    axios.post(`Projects/CloseProjectWithLiability/${company?.id}/${projectId}`, null, {
      params: { user: user?.email }
    })
      .then(() => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Project has been archived.",
        });
        setIsLoadingBudget(true);
        fetchAllBudget();
      }).catch(e => {
        console.log(e)
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Unable to archive project.",
        });
      })
  }

  const handleCloseAndTransfer = (payload) => {
    axios.post("Projects/CloseAndTransferRemaingBudgetItems", payload, {
      params: { user: user?.email }
    })
      .then(() => {
        setSnackBarProps({
          open: true,
          severity: "success",
          text: "Budget has been transferred and project has been archived.",
        });
        setIsLoadingBudget(true);
        fetchAllBudget();
      }).catch(e => {
        console.log(e)
        setSnackBarProps({
          open: true,
          severity: "warning",
          text: "Failed to transfer budget",
        });
      })
  }

  return (
    <div>
      <div className="client-root">
        <Snacky snackprops={snackBarProps} setSnackBarProps={setSnackBarProps} />
        <ClientTabs
          fetchAllBudget={fetchAllBudget}
          company={company}
          value={activeTab}
          hasMediaSpend={hasMediaSpend}
          hasPoiSpend={hasPoiSpend}
          hasInfluencerSpend={hasInfluencerSpend}
          handleChange={(e, newValue) => {
            setActiveTab(newValue);
            // updates the url without reloading entire page
            window.history.replaceState(
              null,
              "Production Core",
              `/Client/Home/${id}/${newValue}/${campaignId ? campaignId : ""}`
            );
          }}
          budget={budget}
          updateHiddenStatus={updateHiddenStatus}
          isLoadingBudget={isLoadingBudget}
          preLoadCampaignById={preLoadCampaignById}
          handlePreLoadCampaign={handlePreLoadCampaign}
          handleCloseProject={handleCloseProject}
          handleCloseAndTransfer={handleCloseAndTransfer}
          handleCloseProjectWithLiability={handleCloseProjectWithLiability}
          setSnackBarProps={setSnackBarProps}
        />
      </div>
    </div>
  );
};

export default Client;
