import React, { useState, useEffect } from "react";
import axios from "axios";
import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import { connect } from "react-redux";
import {
  withStyles,
  makeStyles,
  useTheme,
  styled,
} from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./SpamBot.css";
import { setSnackBarProps } from "../../../actions/snackbar";
import { setDialogProps } from "../../../actions/dialog";
import { RightClickMenu } from "./RightClickMenus";
import ContractorPopUp from "../../Project/ProjectOutputs/PopUps/ContractorPopUp";
import SpamItemButton from "./SpamItemButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { getCurrentTime, convertDatetime } from "../../../utils/TimeHelpers";
import Dialogy from "../../Shared/Dialogy";
import LocationDetailsPopUp from "../../Project/Schedule/Location/LocationDetailsPopUp";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  TableSortLabel,
  Tooltip,
} from "@material-ui/core";
import { format } from "date-fns";
import { updateLocationContact } from "../../../actions/schedules";
import { setProjectFilter } from "../../../actions/adminMenu";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    padding: "20px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100px",
    textOverflow: "ellipsis",
  },
  root: {
    padding: "10px 15px 10px 15px",
    maxHeight: "50px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 12,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledButton = styled(Button)({
  color: "#000000",
  borderRadius: "20%",
});

const headCells = [
  {
    id: "firstName",
    align: "left",
    label: "Name",
  },
  {
    id: "projectName",
    align: "left",
    label: "Project Name",
  },
  {
    id: "type",
    align: "left",
    label: "Type",
  },
  {
    id: "dueDate",
    align: "left",
    label: "Due Date",
  },
  {
    id: "amountDue",
    align: "left",
    label: "Amount Due",
  },
  {
    id: "lastSent",
    align: "left",
    label: "Last Sent",
  },
  {
    id: "nextSend",
    align: "left",
    label: "Next Send",
  },
  {
    id: "status",
    align: "left",
    label: "Status",
  },
  // {
  //   id: "checkedIn",
  //   align: "left",
  //   label: "Checked In",
  // },
  {
    id: "delete",
    align: "left",
    label: "Delete",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};

const initialStatusFilters = [
  "Calendar Sent",
  "Contract Not Sent",
  "Contract Signed",
  "Invoiced",
  "Order Accepted",
  "Order Sent",
  "null",
];
const initialProjectNameFilters = ["Xero Code Testing - May 2020"];

const SpamBotTable = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [spamList, setSpamList] = useState(
    props.spamList ? props.spamList : []
  );
  const [showWarning, setShowWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [redirectToProject, setRedirectToProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const [rightClickOpen, setRightClickOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const [openContactDetailsPopUp, setOpenContactDetailsPopUp] = useState(false);
  const [currentSpamType, setCurrentSpamType] = useState(null);

  const [currentScheduleItemId, setCurrentScheduleItemId] = useState(null);
  const [currentLocationId, setCurrentLocationId] = useState(null);
  const [currentScheduleId, setCurrentScheduleId] = useState(null);
  const [currentCateringId, setCurrentCateringId] = useState(null);
  const [currentPersonId, setCurrentPersonId] = useState(null);

  const [openLocationDetailsPopUp, setOpenLocationDetailsPopUp] = useState(
    false
  );
  const [currentLocation, setCurrentLocation] = useState(null);

  const handleProjectClicked = (row) => {
    setSelectedRow(row)
    setRedirectToProject(true);
  };

  const handleCloseContext = () => {
    setState(initialState);
    setRightClickOpen(false);
  };

  const handleContextClick = (event, rowObj) => {
    if (rightClickOpen == true) {
      event.preventDefault();
      setState(initialState);
      setRightClickOpen(false);
      setCurrentScheduleItemId();
      setCurrentLocationId();
      setCurrentScheduleId();
      setCurrentCateringId();
    } else {
      event.preventDefault();
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
      setRightClickOpen(true);
      setCurrentScheduleItemId(rowObj.scheduleItemId);
      setCurrentLocationId(rowObj.locationId);
      setCurrentScheduleId(rowObj.scheduleId);
      setCurrentCateringId(rowObj.cateringId);
    }
  };

  const handlePersonClick = (event, rowObj) => {
    if (
      rowObj.type === "Contractor" ||
      rowObj.type === "Talent" ||
      rowObj.type === "Staff" ||
      rowObj.type === "Client"
    ) {
      setCurrentPersonId(rowObj.personId);
      setCurrentScheduleItemId(rowObj.scheduleItemId);
      setCurrentSpamType(rowObj.type);
      setOpenContactDetailsPopUp(true);
    } else if (rowObj.type === "Location") {
      openLocationDetails(rowObj);
    }
  };

  const checkId = (spamItem) => {
    switch (spamItem.type) {
      case "Contractor":
      case "Talent":
      case "Staff":
      case "Client":
        return spamItem.scheduleItemId === currentScheduleItemId;
      case "Location":
        return (
          spamItem.scheduleId === currentScheduleId &&
          spamItem.locationId === currentLocationId
        );
      case "Catering":
        return spamItem.cateringId === currentCateringId;
      default:
        return false;
    }
  };

  const checkIfNameClickable = (spamObj) => {
    if (
      spamObj.type === "Contractor" ||
      spamObj.type === "Talent" ||
      spamObj.type === "Staff" ||
      spamObj.type === "Client" ||
      spamObj.type === "Location"
    ) {
      return "pointer clickable";
    }
    return "";
  };

  const getHeaderClass = (cellId) => {
    switch (cellId) {
      case "projectName":
        return "headCell projectNameHeaderColumn";
      case "dueDate":
        return "headCell dueDateHeaderColumn";
      case "type":
        return "headCell typeHeaderColumn";
      case "status":
        return "headCell statusHeaderColumn";
      case "delete":
        return "headCell deleteHeaderColumn";
      default:
        return "headCell";
    }
  };

  const renameStatuses = (itemValue) => {
    switch (itemValue) {
      case "Pending":
        return "Contract Pending";
      case "InvoicePending":
        return "Invoice Pending";
      case "Invoiced":
        return "Invoiced";
      case "AwaitingPayment":
        return "PO Sent";
      case "Signed":
        return "Contract Signed";
      case "CateringSent":
        return "Order Sent";
      case "CalendarSent":
        return "Calendar Sent";
      case "Accepted":
        return "Order Accepted";
      case "Send":
      case "Disabled":
        return "Contract Not Sent";
      case "Accepted":
        return "Order Accepted";
      case "Cancelling":
          return "Cancelling Catering";
      case "Cancelled":
          return "Order Cancelled";
      default:
        return "Can't find type";
    }
  };

  const renameCheckInStatuses = (itemValue) => {
    switch (itemValue) {
      case 0:
        return "Not Checked In";
      case 1:
        return "Checked In";
      default:
        return "Can't find type";
    }
  };

  const handleHeadCell = (cell) => {
    if (cell.id === "dueDate") {
      return (
        <th
          filterkey={cell.id}
          className={getHeaderClass(cell.id)}
          itemDisplayValueFunc={(itemValue) => {
            // console.log(itemValue);
            if (itemValue !== null) {
              return itemValue.toDateString();
            }
          }}
          itemSortValueFunc={(itemValue) => {
            // console.log(itemValue);
            if (itemValue !== "(blank)") {
              return itemValue.toDateString();
            }
          }}
          alignleft="true"
        >
          {cell.label}
        </th>
      );
    } else if (cell.id === "nextSend" || cell.id === "lastSent") {
      return (
        <th
          filterkey={cell.id}
          className={getHeaderClass(cell.id)}
          itemDisplayValueFunc={(itemValue) => {
            // console.log(itemValue);
            if (itemValue !== null) {
              return format(itemValue, "dd/MM/yyyy hh:mm a");
            }
          }}
          itemSortValueFunc={(itemValue) => {
            // console.log(itemValue);
            if (itemValue !== "(blank)") {
              return format(itemValue, "dd/MM/yyyy hh:mm a");
            }
          }}
          alignleft="true"
        >
          {cell.label}
        </th>
      );
    } else if (cell.id === "status") {
      return (
        <th
          filterkey={cell.id}
          className={getHeaderClass(cell.id)}
          itemDisplayValueFunc={(itemValue) => {
            if (itemValue !== null) {
              return renameStatuses(itemValue);
            }
          }}
          itemSortValueFunc={(itemValue) => {
            console.log(itemValue);
            if (itemValue !== "(blank)") {
              return renameStatuses(itemValue);
            }
          }}
          alignleft="true"
        >
          {cell.label}
        </th>
      );
    } else if (cell.id === "checkedIn") {
      return (
        <th
          filterkey={cell.id}
          className={getHeaderClass(cell.id)}
          itemDisplayValueFunc={(itemValue) => {
            if (itemValue !== null) {
              return renameCheckInStatuses(itemValue);
            }
          }}
          itemSortValueFunc={(itemValue) => {
            console.log(itemValue);
            if (itemValue !== "(blank)") {
              return renameCheckInStatuses(itemValue);
            }
          }}
          alignleft="true"
        >
          {cell.label}
        </th>
      );
    } else {
      return (
        <th
          filterkey={cell.id}
          className={getHeaderClass(cell.id)}
          alignleft="true"
        >
          {cell.label}
        </th>
      );
    }
  };

  const handleSpamItemEdit = (value, name, spamItem) => {
    setSpamList(
      spamList.map((s) =>
        s.projectId === spamItem.projectId &&
        s.scheduleItemId === spamItem.scheduleItemId &&
        s.locationId === spamItem.locationId &&
        s.scheduleId === spamItem.scheduleId &&
        s.cateringId === spamItem.cateringId
          ? {
              ...s,
              [name]: value,
            }
          : s
      )
    );
  };

  const deleteSpamItem = (spamItem) => {
    setSpamList(spamList.filter((sI) => sI !== spamItem));
    var url = "";
    if (spamItem.personCategory === "Talent") {
      url = `TalentContracts/UpdateOnCancelContract/${spamItem.scheduleItemId}`;
    } else {
      url = `ContractorContracts/UpdateOnCancelContract/${spamItem.scheduleItemId}`;
    }
    axios
      .put(url, {
        dateCancelled: getCurrentTime(),
      })
      .then((res) => {
        console.log(res);
        axios
          .delete(`ScheduleItems/DeleteScheduleItem/${spamItem.scheduleItemId}`)
          .then((res) => {})
          .catch((error) => {});
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const noSpamItems = () => {
    props.setTotalDueOnScreen(0);
    return <React.Fragment></React.Fragment>;
  };

  //----------Location Details-----------
  const openLocationDetails = (spamItem) => {
    axios
      .get(`Locations/GetLocation/${spamItem.locationId}`)
      .then((res) => {
        console.log(res);
        setCurrentLocation(res.data);
        setOpenLocationDetailsPopUp(true);
      })
      .catch((e) => {
        console.log("Fetch Location Failed");
        console.log(e);
      });
  };
  const setLoadingImagesFalse = () => {
    console.log("setLoadingImagesFalse");
  };
  const setLoadingImagesTrue = () => {
    console.log("setLoadingImagesTrue");
  };
  //-------------------------------------
  if (redirectToProject) {
    if((selectedRow?.personCategory === "Influencer" || selectedRow?.personCategory === "Content Creator") && selectedRow?.campaignId && selectedRow?.companyId){
      history.push(`/Client/Home/${selectedRow?.companyId}/1/${selectedRow.campaignId}`);
      props.setProjectFilter(null);
    }else{
      history.push(`/Project/Home/${selectedRow.projectId}/0`);
    }
    
    props.setProjectFilter(null);
    return <React.Fragment></React.Fragment>;
  } else {
    var totalDue = 0.0;
    return (
      <React.Fragment>
        {openContactDetailsPopUp && (
          <ContractorPopUp
            handleModal={() => {
              setOpenContactDetailsPopUp(false);
            }}
            modalState={openContactDetailsPopUp}
            personId={currentPersonId}
            scheduleItemId={currentScheduleItemId}
            reloadPersonList={() => console.log("No reload")}
            reloadScheduleItemsList={() => console.log("No reload")}
            type="EditScheduleItem"
            providerType={currentSpamType}
          />
        )}
        {showWarning ? (
          <Dialogy
            setDialogProps={setDialogProps}
            buttonProps={{
              show: showWarning,
              titleText: "Are you sure you want to delete?",
              contentText:
                "This will permenantly delete this from the database",
              buttonOneColor: "primary",
              buttonOneText: "Cancel",
              buttonTwoColor: "primary",
              buttonTwoText: "Ok",
            }}
            handleButtonOne={() => setShowWarning(false)}
            handleButtonTwo={() => deleteSpamItem(selectedRow)}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {openLocationDetailsPopUp ? (
          <LocationDetailsPopUp
            updateLocationContact={updateLocationContact}
            parent={"SpamBotTable"}
            location={currentLocation}
            modalState={openLocationDetailsPopUp}
            initialTab={2}
            handleModal={() => {
              setCurrentLocation(null);
              setOpenLocationDetailsPopUp(false);
            }}
            setLoadingImagesFalse={() => setLoadingImagesFalse()}
            setLoadingImagesTrue={() => setLoadingImagesTrue()}
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <Table
          className={classes.table}
          size="small"
          aria-label="customized table"
          style={{ tableLayout: "fixed" }}
        >
          <TableFilter
            rows={spamList}
            onFilterUpdate={(updatedData, filterConfiguration) => {
              setSpamList(updatedData);
              // console.log("filterConfiguration")
              // console.log(filterConfiguration)
            }}
            // initialFilters={{ status: initialStatusFilters, projectName: initialProjectNameFilters }}
            initialFilters={props.initialFilters}
          >
            {headCells.map((cell) => {
              return handleHeadCell(cell);
            })}
          </TableFilter>
          <TableBody>
            {/* {spamList.length > 0 ?
                            stableSort(spamList, getComparator(order, orderBy))
                                .map((row, index) => { */}
            {spamList.length > 0
              ? spamList.map((row, index) => {
                  if (row.amountDue !== null) {
                    totalDue = totalDue + parseFloat(row.amountDue);
                  }
                  props.setTotalDueOnScreen(totalDue);
                  return (
                    <StyledTableRow
                      key={
                        spamList.scheduleItemId !== null
                          ? spamList.scheduleItemId
                          : spamList.cateringId
                      }
                      hover={true}
                    >
                      <StyledTableCell
                        align="left"
                        onContextMenu={(event) => {
                          event.preventDefault();
                          handleContextClick(event, row);
                        }}
                        onClick={(event) => {
                          if (!rightClickOpen) {
                            handlePersonClick(event, row);
                          }
                        }}
                        className="bodyCell"
                      >
                        <span className={checkIfNameClickable(row)}>
                          {row.lastName
                            ? `${row.firstName} ${row.lastName}`
                            : row.firstName}
                          {rightClickOpen && checkId(row) ? (
                            <RightClickMenu
                              state={state}
                              spamItem={row}
                              handleCloseContext={handleCloseContext}
                              setSnackBarProps={props.setSnackBarProps}
                            />
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bodyCell">
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <p>
                                <b>{row.projectName}</b>
                              </p>
                              <p>
                                <em>{(row.personCategory === "Influencer" || row.personCategory === "Content Creator") ? row.personCategory : row.scheduleName}</em>
                              </p>
                            </React.Fragment>
                          }
                          placement={"top-start"}
                        >
                          <span
                            className="clickable"
                            onClick={() => handleProjectClicked(row)}
                          >
                            {row.projectName}
                          </span>
                        </HtmlTooltip>
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bodyCell">
                        {row.type}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bodyCell">
                        {row.dueDate !== null
                          ? format(row.dueDate, "dd/MM/yyyy")
                          : null}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bodyCell">
                        {row.amountDue ? (
                          `$${row.amountDue}`
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bodyCell">
                        {row.lastSent !== null
                          ? format(row.lastSent, "dd/MM/yyyy hh:mm a")
                          : null}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="bodyCell">
                        {row.nextSend !== null
                          ? format(row.nextSend, "dd/MM/yyyy hh:mm a")
                          : null}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left" className="bodyCell" style={{ maxWidth: 50 }}>
                                            <SpamItemButton
                                                spamItem={row}
                                                providerType={row.type}
                                                handleOnClick={handleSpamItemEdit}
                                            />
                                        </StyledTableCell> */}
                      <StyledTableCell align="left" className="bodyCell">
                        {/* {row.status} */}
                        {/* {renameStatuses(row.status)} */}
                        <SpamItemButton
                          spamItem={row}
                          providerType={row.type}
                          handleOnClick={handleSpamItemEdit}
                        />
                      </StyledTableCell>
                      {/* <StyledTableCell
                        align="center"
                        className="bodyCell"
                        style={{ maxWidth: 50 }}
                      >
                        {row.checkedIn === 1 && row.willAttendShoot === 1 ? (
                          <CheckCircleIcon
                            style={{
                              color: "#4dfa59",
                            }}
                          />
                        ) : row.checkedIn === 0 && row.willAttendShoot === 1 ? (
                          <NotInterestedIcon
                            style={{
                              color: "#ed9c91",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </StyledTableCell> */}
                      <StyledTableCell
                        align="left"
                        className="bodyCell"
                        style={{ maxWidth: 50 }}
                      >
                        {row.status === "Pending" ? (
                          <StyledButton
                            onClick={() => {
                              setSelectedRow(row);
                              setShowWarning(true);
                            }}
                          >
                            <DeleteIcon
                              style={{ color: "rgba(0, 0, 0, 0.54)" }}
                            />
                          </StyledButton>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : noSpamItems()}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state, ownProps) => ({
  snackbar: state.snackbar,
  dialog: state.dialog,
});
export default connect(mapStateToProps, {
  setSnackBarProps,
  setDialogProps,
  updateLocationContact,
})(SpamBotTable);
